import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useEffect, useState } from "react";
import { Loading } from ".";
import { Auth } from 'aws-amplify';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useDispatch } from 'react-redux';
import { getCredits, setCredits } from "../Credit-slice";
import { LoadingEmbed } from "./loading";
import Alert from './snackbar-message';
import generatePassword from "../utils/generate-password";
import getToken from "../utils/get-token";

const DISPLAY_PAGE = 10;

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

let interactionTimer = null;

const Manufacturers = withAuth0(({filter}) => {
  const classes = useStyles();
  const [makeRoleRequest, setRoleRequest] = useState(null);
  const [userData, setUserData] = useState([]);
  const [nextToken, setNextToken] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [ requestNumber, setRequestNumber ] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [pauseLoading, setPauseLoading] = useState(true);
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchUserData = async (nextToken, searchVal='') => {
    let urlBuilder = new URL(`${apiUrl}/manufacturers`);
    urlBuilder.search = new URLSearchParams({
      nextToken: nextToken ? nextToken : '',
    });

    let url = urlBuilder.toString();
    console.log(url)
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": await getToken(),
      },
    })
    
    return response.json();
  }

  useEffect(() => {
    setNextToken('');
    setPageIndex(0);
    setUserData([]);
    setNoResults(false);
    setSearchVal(filter);
    setPauseLoading(false);
  }, [filter])

  useEffect(() => {
    if(pauseLoading){
      return;
    }

    if ((pageIndex + 1) >= userData.length / DISPLAY_PAGE && (nextToken !== '' || pageIndex == 0)){
      fetchUserData(nextToken, searchVal).then((result)=> { 
        const {userGroup, token} = result;
        console.log(result)

        setNextToken(token ? token : '');
        setUserData(userData.concat(userGroup));
        if(userGroup.length == 0 && pageIndex == 0){
          setNoResults(true);
        }
      });
    }
  }, [pageIndex, searchVal, pauseLoading]);

  useEffect(() => {
      fetchUserData(nextToken, searchVal).then((result)=> { 
        const {userGroup, token} = result;
        console.log(result)

        setNextToken(token ? token : '');
        setUserData(userGroup);
        if(userGroup.length == 0 && pageIndex == 0){
          setNoResults(true);
        }
      });
  }, [makeRoleRequest]);
  
  const modifyPageIndex = (isIncrement) => {
    console.log(nextToken);
    if (!isIncrement){
      setPageIndex(pageIndex - 1);
      return;
    } else {
      setPageIndex(pageIndex + 1);
    } 
  }
  
  const fetchCredits = async() =>
    {
      let currentUser = Auth.user.signInUserSession.idToken.payload["cognito:username"];
      const response = await fetch(`${apiUrl}/doctors/${currentUser}/credits`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
      })
      
      return response.json();
    }

  useEffect(() => {
    fetchCredits().then((result)=> {
      dispatch(setCredits(result.credits))
    }); 
  }, [])

  useEffect(() => {
    if(!makeRoleRequest){
      return;
    }

    const fetchData = async () => {
      let newRole;
      console.log(makeRoleRequest);

      if (makeRoleRequest.role === "Doctor"){
        console.log("set role request: " + makeRoleRequest.role)
        newRole = "Doctor";
      }
      else if (makeRoleRequest.role === "Patient"){
        console.log("set role request: " + makeRoleRequest.role)
        newRole = "Patient";
      }
      else{
        console.log("set role request: " + makeRoleRequest.role)
        newRole = "Admin";
      }
  
      const response = await fetch(`${apiUrl}/users/${makeRoleRequest.username}/updaterole`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
        body: JSON.stringify({userGroup: newRole}),
      });
        
      console.log(response);

      setRoleRequest(null);
      setRequestNumber(r => r + 1);
    }

    fetchData();
  }, [makeRoleRequest]);

  async function deleteAccount(username) {
    const fetchData = async () => {
      const npiResponse = await fetch(`${apiUrl}/users/${username}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
      })
      console.log(npiResponse);
      return npiResponse.json();
    }

    fetchData().then((result)=> {
      console.log(result);
      if(result.success){
        let userListItem = userData.find(u => u.username == username);
        console.log(userListItem);
        if(userListItem){
          userListItem.deleted = true;
        }
        setUserData([...userData]);
      }
    });
  };
  
  const IsolatedMenu = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
   
    return(
    <React.Fragment>
      <Button
      variant ="contained"
      aria-label="outlined primary button group"
      aria-controls="simple-menu"
      aria-haspopup="true"
      onClick={event => setAnchorEl(event.currentTarget)}
      >
        <ArrowDropDownIcon />
      </Button>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.patient.username,
              role: "Doctor"
            })
        }}>Make Doctor</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.patient.username,
              role: "Patient"
            })
        }}>Make Patient</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.patient.username,
              role: "Admin"
            })
        }}>Make Admin</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            deleteAccount(props.patient.username);
        }}>Delete Account</MenuItem>
      </Menu>
    </React.Fragment>
    )
  }

  if (makeRoleRequest) {
    return <Loading />;
  }

  let dataSubset = [];
  if (userData){
    dataSubset = userData.slice(pageIndex* DISPLAY_PAGE, (pageIndex*DISPLAY_PAGE) + DISPLAY_PAGE);
  }
  return (
    <>
      <Container className={classes.container}>
        &nbsp;
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Manufacturers</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h5">
              Manufacturer E-mail
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h5">
              Account Created
            </Typography>
          </Grid>  
          <Grid item xs={1} style={{textAlign: 'right'}}></Grid>

          {noResults && 
            <Grid item xs={12} style={{textAlign: 'center'}}>
              <Typography variant="h5">
                No results
              </Typography>
            </Grid>
          }
          {!noResults && dataSubset.length == 0 && <Grid item xs={12}><LoadingEmbed/></Grid>}

          {dataSubset && dataSubset.sort((a, b) => a.email - b.email).map(patient => {
            if(patient.deleted){
              return <React.Fragment key={patient.email}>
                <Grid item xs={7}>
                  <Typography variant="body1">
                    [Deleted User]
                  </Typography>
                </Grid>
                <Grid item xs={2}>{patient.created}</Grid>
                <Grid item xs={2}>DELETED</Grid>
              </React.Fragment>;
            }

            return <React.Fragment key={patient.email}>
              <Grid item xs={5}>
                <Typography variant="body1">
                  {patient.email}
                </Typography>
              </Grid>
              <Grid item xs={5}>{patient.created}</Grid>
              
              <Grid item xs={1} style={{textAlign: 'right'}}>
                <IsolatedMenu patient={patient}/>
              </Grid>              
            </React.Fragment>;
          })}
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
          <Grid item>
            <Button onClick={() => modifyPageIndex(false)} disabled={pageIndex === 0}><ArrowBackIosIcon/></Button>
            <Button onClick={() => modifyPageIndex(true)} disabled={nextToken == '' && ((pageIndex + 1)*DISPLAY_PAGE >= userData.length)}><ArrowForwardIosIcon/></Button>
          </Grid>
        </Grid>       
      </Container>
    </>
    );
  
});

export default Manufacturers;
