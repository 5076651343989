import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio, 
  Box,
  Tabs,
  Tab
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useEffect, useState } from "react";
import awsconfig from "../aws-exports";
import Amplify from "aws-amplify";
import Alert from './snackbar-message';
import getToken from "../utils/get-token";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

Amplify.configure(awsconfig);

const AddPatient = withAuth0(() => {
  const classes = useStyles();
  const [doctorEmail, setDoctorEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDoctor, setIsDoctor] = useState(0);
  const [patientEmail, setPatientEmail] = useState(null);
  const [patientName, setPatientName] = useState(null);
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [preferredMethod, setPreferredMethod] = useState('email');
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [saved, setSaved] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [NPI, setNPI] = useState('');
  const [npiError, setNpiError] = useState('');
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [waitingForCode, setWaitingForCode] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [code, setCode] = useState("");
  const [ completeSignupRequest, setCompleteSignupRequest ] = useState(false);
  const [help, setHelp] = useState(false);
  const [open, setOpen] = useState(false);
  const [helpEmail, setHelpEmail] = useState(null);
  const [helpNPI, setHelpNPI] = useState("");
  const [helpMessage, setHelpMessage] = useState("");
  const [codeResent, setCodeResent] = useState(false);
  const [helpSent, setHelpSent] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [error, setError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [helpError, setHelpError] = useState(false);
  const [codeErrorText, setCodeErrorText] = useState('');
  const [npiErrorText, setNpiErrorText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [helpErrorText, setHelpErrorText] = useState('');
  const [emailErrorText, setEmailErrorText] = useState('');
  const passwordErrorText = "Your password must be at least 8 characters long";
  const nameErrorText = "Name is required";
  const token = getToken();
  const apiUrl = process.env.REACT_APP_API_URL;

  const lookupNPI = async () => {
    const npiResponse = await fetch(`${apiUrl}/npi/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({npi: NPI}),
    });
    return npiResponse.json();
  }
  const fetchUserStatus = async (email) => {
    const response = await fetch(`${apiUrl}/users/${email}/status`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.json();
  }

  const handleClickHelpOpen = () => {
    setOpen(true);
  };

  const handleToggleDoctor = (_event, newValue) =>{
    setIsDoctor(newValue);
  }
  
  const checkNPI = (e)=> {
    setCodeResent(false);
    setCodeError(false);
    setNpiError(false);
    setError(false);
    setPasswordError(false);
    e.preventDefault();
    console.log(NPI)
    const len = Math.ceil(Math.log10(NPI));
    if (len === 10){
      lookupNPI()
        .then((result) => {
          console.log(result)
          if(result.length === 1) {
            setName(result[0].name);
            let doctorName = result[0].name;
            let doctorLoc = result[0].location;

            setLocation(result[0].location);
            saveDoctorChanges(doctorName, doctorLoc);
            return true;
          }
          else{
            setNpiError(true);
            setNpiErrorText("Error: Invalid NPI");
            setHelp(true);
            return false;
          }
        });
    }
    else{
      setNpiError(true);
      setNpiErrorText("Error: Invalid NPI");
      setHelp(true);
      return false;
    }
  }

  async function saveDoctorChanges(doctorName, doctorLoc) {
    setSaved(false);
    setNameError(false);
    setEmailError(false);
    console.log("in doctor account creation", doctorName, " : ", doctorEmail)
    if(isDoctor) {
      const apiUrl = process.env.REACT_APP_API_URL;
      let docEmail  = doctorEmail.toString();
      const updateResponse = await fetch(`${apiUrl}/users/${doctorEmail}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          isPatient: "false",
          isDoctor: "true",
          name: doctorName,
          location: doctorLoc,
          npi: NPI,
          phone: "",
          preferredMethod: ""
        }),
      });
      if(updateResponse.status === 200) {
        setAlertSeverity("success");
        setAlertText("Account created successfully");
        setSaved(true);
      }
      if(updateResponse.status === 500) {
        setAlertSeverity("error");
        setAlertText("Error: An error occured trying to create this user")
        setSaved(true);
      }
      if(updateResponse.status === 403) {
        setAlertSeverity("error");
        setAlertText("Error: User already exists")
        setSaved(true);
      }
    }
    else {
      if (!doctorName) {
        setNameError(true);
      }
      if (!doctorName) {
        setEmailError(true);
      }
    }

  }

  async function savePatientChanges() {
    setSaved(false);
    setNameError(false);
    setEmailError(false);
    if(patientName && patientEmail) {
      const apiUrl = process.env.REACT_APP_API_URL;
      const updateResponse = await fetch(`${apiUrl}/patients/${patientEmail}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
        body: JSON.stringify({
          username: patientEmail,
          name: patientName,
          email: patientEmail,
          address: address,
          phone: phoneNumber,
          
          preferredContactMethod: preferredMethod,
        }),
      });
      console.log(updateResponse);
      if(updateResponse.status === 200) {
        setAlertSeverity("success");
        setAlertText("Account created successfully");
        setSaved(true);
      }
      if(updateResponse.status === 500) {
        setAlertSeverity("error");
        setAlertText("Error: An error occured trying to create this user")
        setSaved(true);
      }
      if(updateResponse.status === 403) {
        setAlertSeverity("error");
        setAlertText("Error: User already exists")
        setSaved(true);
      }
    }
    else {
      if (!patientName) {
        setNameError(true);
      }
      if (!patientEmail) {
        setEmailError(true);
      }
    }

  }

  return(
    <>
        <Tabs
          value={isDoctor}
          onChange={handleToggleDoctor}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Add Patient" />
          <Tab label="Add Doctor" />
        </Tabs>
        {!isDoctor?<Container className={classes.container}>
        <TextField
          margin="normal"
          variant="outlined"
          required
          fullWidth
          id="sign-up-email"
          label="Patient Email"
          name="patientEmail"
          autoComplete="email"
          type="email"
          error={emailError}
          onChange={(e) => setPatientEmail(e.target.value)}
        />
        {emailError && (
          <Grid item>
            <Typography style={{ color: 'red', fontSize: '15px' }}>{emailErrorText}</Typography>
          </Grid>
        )}
        <TextField
          margin="normal"
          variant="outlined"
          required
          fullWidth
          id="sign-up-name"
          label="Patient Name"
          name="patientName"
          autoComplete="name"
          type="name"
          error={nameError}
          onChange={(e) => setPatientName(e.target.value)}
        />
        {nameError && (
          <Grid item>
            <Typography style={{ color: 'red', fontSize: '15px' }}>{nameErrorText}</Typography>
          </Grid>
        )}
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          id="sign-up-address"
          label="Patient Address"
          name="patientAddress"
          autoComplete="address"
          type="address"
          onChange={(e) => setAddress(e.target.value)}
        />
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          id="sign-up-number"
          label="Patient Phone Number"
          name="patientName"
          autoComplete="phone"
          type="phone"
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <br/>
        <br/>
        <Grid item xs={12}>
          <FormLabel component="legend">Patient Preferred Method of Contact</FormLabel>
          <RadioGroup aria-label="preferred contact" name="preferredmethod" value={preferredMethod} onChange={(e) => setPreferredMethod(e.target.value)}>
            <FormControlLabel value="email" control={<Radio />} label="Email" />
            <FormControlLabel value="phone" control={<Radio />} label="Phone" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign='center'>
            <Button 
              id="save"
              name="save"
              variant ="contained"
              fullWidth
              onClick={() => savePatientChanges()}
            >
                Create Patient Account
            </Button>
          </Box>
        </Grid>
        {saved && <Alert children={alertText} severity={alertSeverity}/>}
      </Container>:
      <div><TextField
                margin="normal"
                variant="outlined"
                required
                fullWidth
                id="sign-up-email"
                label="Email Address"
                name="email"
                autoComplete="email"
                type="email"
                value={doctorEmail}
                error={emailError}
                onChange={(e) => setDoctorEmail(e.target.value)}
              />
              {emailError && (
                <Grid item>
                  <Typography style={{ color: 'red', fontSize: '15px' }}>{emailErrorText}</Typography>
                </Grid>
              )}
                  <TextField
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    id="sign-up-npi"
                    label="NPI"
                    name="npi"
                    autoComplete="npi"
                    type="npi"
                    value={NPI}
                    error={npiError}
                    onChange={(e) => setNPI(e.target.value)}
                  />
                  {npiError && (
                    <Grid item>
                      <Typography style={{ color: 'red', fontSize: '15px' }}>{npiErrorText}</Typography>
                    </Grid>
                  )}
                  {error && (
                    <Grid item>
                      <Typography style={{ color: 'red', fontSize: '15px' }}>{errorText}</Typography>
                    </Grid>
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={checkNPI}
                  >
                  {isDoctor?"Create Doctor Account":"Create Patient Account"}
                  </Button>
                {help && (
                  <Grid item style={{textAlign: 'center'}} xs={12}>
                    <Button 
                      type="button"
                      style={{marginTop: '16px'}} 
                      onClick={handleClickHelpOpen}
                    >
                      Need Help Signing Up? Contact Us
                    </Button>
                  </Grid>
                )}
                {saved && <Alert children={alertText} severity={alertSeverity}/>}
                </div>      }  
    </>
  );
});

export default AddPatient;
