import {
  Box,
  Grid,
  Typography,
  Container,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useEffect, useState } from "react";
import { Loading } from ".";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useParams } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import { Auth } from 'aws-amplify';
import Alert from './snackbar-message';
import { useDispatch } from 'react-redux';
import { getCredits, setCredits } from "../Credit-slice";
import getToken from "../utils/get-token";

let role = "";
const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
  };
});
    
export default function Prescriptions() {
  const classes = useStyles();
  const [data, setData] = useState("");
  const [gotData, setGotData] = useState(false);
  const [groupData, setGroupData] = useState("");
  const [newPrescriptionAdded, setNewPrescriptionAdded] = useState(false);
  const [prescribingDoctor, setPrescribingDoctor] = useState(false);
  let { userId } = useParams();  
  const apiUrl = process.env.REACT_APP_API_URL;
  role = (Auth.user.signInUserSession.idToken.payload["cognito:groups"]).toString();
  let dispatch = useDispatch()
  const token = getToken();

  useEffect(() => {
    fetchCredits().then((result)=> {
      console.log(result)
      dispatch(setCredits(result.credits))
    }); 
  }, [newPrescriptionAdded])

  
  useEffect(() => {
    const fetchPrivilegeData = async() => {
      let currentUser = Auth.user.signInUserSession.idToken.payload["cognito:username"];
      role = (Auth.user.signInUserSession.idToken.payload["cognito:groups"]).toString();
      const prescriptionResponse = await fetch(`${apiUrl}/doctors/${currentUser}/prescriber`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
      })
      return prescriptionResponse.json();
    }

    let role = (Auth.user.signInUserSession.idToken.payload["cognito:groups"]).toString();
    if(role === "Doctor"){
      fetchPrivilegeData().then((result)=> {
        if(result[0].prescriber) {
          setPrescribingDoctor(true);
        }
      });
    }
    else if(role === 'Admin') {
      setPrescribingDoctor(true);
    }
    
  },[]);
  const fetchPrescriptionData  = async() =>
  {
    const prescriptionResponse = await fetch(`${apiUrl}/patients/${userId}/prescriptions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": await getToken(),
      },
    })
    return prescriptionResponse.json();
  }
const fetchCredits = async() =>
  {
    let currentUser = Auth.user.signInUserSession.idToken.payload["cognito:username"];
    const response = await fetch(`${apiUrl}/doctors/${currentUser}/credits`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": await getToken(),
      },
    })
    
    return response.json();
  }
  useEffect(() => {
    fetchPrescriptionData() 
      .then((result)=> {
        let result_activated = [];
        let prescriptionGroup = [];
        for(let i = 0; i< result.length; i++) {
          if(result[i].start_date) {
            result_activated.push(result[i])
          }
        }
        if(result_activated.length > 0){
          result_activated.sort(function(a,b){
            return new Date(b.start_date) - new Date(a.start_date);
          });
          console.log(result_activated)
          let today = (new Date()).toISOString();
          if(result_activated[0].end_date > today) {  //check if the latest prescription is active
            //Build the current prescription group
            prescriptionGroup.push(result_activated[0])
            for(i = 1; i < result_activated.length; i++) {
              if(result_activated[i].next_id == result_activated[i-1].prescription && result_activated[i].device == result_activated[i-1].device) {
                prescriptionGroup.push(result_activated[i])
              }
              else {
                break;
              }
            }
          }
        }
        console.log(prescriptionGroup)
        setGroupData(prescriptionGroup)
        setData(result)
        setGotData(true)  
      }); 
      fetchCredits().then((result)=> {
        localStorage.setItem('credits', result.credits);
        console.log(result);
    }); 
  }, [newPrescriptionAdded]);
 
  async function addPrescription(numDays) {
    setNewPrescriptionAdded(false);
    let newDate = (new Date()).toISOString();
    
    const fetchData = async () => {  
      let current_user = (Auth.user.signInUserSession.idToken.payload["cognito:username"]).toString();
      const prescriptionResponse = await fetch(`${apiUrl}/patients/${userId}/prescriptions`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
        body: JSON.stringify({
          prescription: newDate, 
          days: numDays,
          prescriber: current_user,
        }),
      });
      console.log(prescriptionResponse);
      setNewPrescriptionAdded(!newPrescriptionAdded);
      return prescriptionResponse.json();
    }
    //Fetch credits
    fetchCredits().then((result)=> {
      role = (Auth.user.signInUserSession.idToken.payload["cognito:groups"]).toString();
      let credits = result.credits;

      //check to ensure enough credits to assign prescription before prescription assigned
      if (role !== "Admin" && credits < numDays){
        //user feedback
        alert("Not enough credits to assign prescription")
        return false;
      }
      else{ 
        fetchData().then((result)=> {
          console.log("data results",result);
          fetchPrescriptionData() 
            .then((result)=> {
              setData(result);
              setGotData(true);
              setNewPrescriptionAdded(true);
            });
        }); 
      }
    });   
  };

  if (!gotData) {
    return <Loading />;
  }
  
  let prescription_active = [];
  let prescription_inactive = [];
  for(var i = 0; i < data.length; i++) {
    if(data[i].length_days !== "" && data[i].end_date === ""){
      prescription_inactive.push(data[i]);
    }
    else {
      prescription_active.push(data[i]);
    }
  }

  let sortedActivatedPrescriptions = prescription_active.sort((a, b) => {
    return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
  });
  
  sortedActivatedPrescriptions = sortedActivatedPrescriptions.map((session) => {
    let start_date, start_time, end_date, end_time = "";
    let prescription_device = session.device;
    if(session.start_date !== "") {
      start_date = (new Date(session.start_date)).toLocaleDateString();
      start_time = (new Date(session.start_date)).toLocaleTimeString();
    }
    if(session.end_date !== ""){
      end_date = (new Date(session.end_date)).toLocaleDateString();
      end_time = (new Date(session.end_date)).toLocaleTimeString();
    }
        
    return {
      username: userId,
      prescription: session.prescription,
      startDate: start_date,
      startTime: start_time,
      endDate: end_date,
      endTime: end_time,
      device: prescription_device,
      prescriber: session.prescriber,
    };
  });

  let sortedInactivePrescriptions = prescription_active.sort((a, b) => {
    return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
  });
  
  sortedInactivePrescriptions = prescription_inactive.map((session) => {
    let created_Date = new Date(session.prescription);
    let prescription_length = session.length_days;
    console.log(prescription_length)
    console.log(role)
    return {
      username: userId,
      prescription: session.prescription,
      startDate: created_Date.toLocaleDateString(),
      startTime: created_Date.toLocaleTimeString(),
      prescriptionLength: prescription_length,
      prescriber: session.prescriber,
    };
  });

  let sortedActiveGroup = groupData;
  
  sortedActiveGroup = sortedActiveGroup.map((session) => {
    let start_date, start_time, end_date, end_time = "";
    let prescription_device = session.device;
    if(session.start_date !== "") {
      start_date = (new Date(session.start_date)).toLocaleDateString();
      start_time = (new Date(session.start_date)).toLocaleTimeString();
    }
    if(session.end_date !== ""){
      end_date = (new Date(session.end_date)).toLocaleDateString();
      end_time = (new Date(session.end_date)).toLocaleTimeString();
    }
        
    return {
      username: userId,
      prescription: session.prescription,
      startDate: start_date,
      startTime: start_time,
      endDate: end_date,
      endTime: end_time,
      device: prescription_device,
      prescriber: session.prescriber,
    };
  });

  async function deletePrescription(row) { 
    let prescription = row.prescription;
    let username = row.username;
    const fetchData = async () => {
      console.log(prescription)
      const prescriptionResponse = await fetch(`${apiUrl}/patients/${username}/prescriptions/${prescription}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
      });
      return prescriptionResponse.json();
    };
    
    if (window.confirm("Are you sure you'd like to delete this prescription?")) {
      fetchData()
        .then((responseDelete)=> {
          fetchPrescriptionData() 
            .then((result)=> {
              setData(result);
              setGotData(true);
              if(responseDelete.success) {
                console.log("prescription " + prescription + " deleted.")
              }
            });
        }); 
    };
  };

  return (
    <Container className={classes.container}>
      {newPrescriptionAdded && <Alert children={"New Prescription Successfully Added"}/>}
      
      <Typography variant="h4">Prescription History: {userId}</Typography>
      &nbsp;
      <Grid container spacing={1}>
        {prescribingDoctor && (
          <>
            <Grid item xs={2}>
              <Typography variant="h5">Add a new prescription: </Typography>
            </Grid>
            <Grid item xs={10}>
              <ButtonGroup variant="contained" style={{marginTop: '16px'}}aria-label="contained primary button group">
                {role == "Admin" ?<Button onClick={() => addPrescription(2**32 - 10)}>10 Minutes</Button>:<></> }
                <Button onClick={() => addPrescription(1)}>1 Day</Button>
                <Button onClick={() => addPrescription(7)}>7 Day</Button>
                <Button onClick={() => addPrescription(14)}>14 Day</Button>
                <Button onClick={() => addPrescription(30)}>30 Day</Button>
                {role == "Admin" ? <Button onClick={() => addPrescription(60)}>60 Day</Button>:<></>}
                {role == "Admin" ? <Button onClick={() => addPrescription(90)}>90 Day</Button>:<></>}
                {role == "Admin" ? <Button onClick={() => addPrescription(3650)}>Unlimited</Button>:<></>}
              </ButtonGroup>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Box style={{height: '40px'}}/>
          <TableContainer component={Paper}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div" align="center" style={{fontWeight: 'bold'}}>
                Active Prescription Group
            </Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Start Date</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Start Time</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>End Date</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>End Time</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Device</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Prescriber</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedActiveGroup.map((row) => (
                  <TableRow>
                    <TableCell align="center">{row.startDate}</TableCell>
                    <TableCell align="center">{row.startTime}</TableCell>
                    <TableCell align="center">{row.endDate}</TableCell>
                    <TableCell align="center">{row.endTime}</TableCell>
                    <TableCell align="center">{row.device}</TableCell>
                    <TableCell align="center">{row.prescriber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        &nbsp;
        </Grid>

        <Grid item xs={12}>
          <Box style={{height: '40px'}}/>
          <TableContainer component={Paper}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div" align="center" style={{fontWeight: 'bold'}}>
                Activated Prescriptions
            </Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Start Date</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Start Time</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>End Date</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>End Time</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Device</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Prescriber</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedActivatedPrescriptions.map((row) => (
                  <TableRow>
                    <TableCell align="center">{row.startDate}</TableCell>
                    <TableCell align="center">{row.startTime}</TableCell>
                    <TableCell align="center">{row.endDate}</TableCell>
                    <TableCell align="center">{row.endTime}</TableCell>
                    <TableCell align="center">{row.device}</TableCell>
                    <TableCell align="center">{row.prescriber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        &nbsp;
        </Grid>
        <Grid item xs={12}>
          <Box style={{height: '40px'}}/>
          <TableContainer component={Paper}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div" align="center" style={{fontWeight: 'bold'}}>
              Available Prescriptions
            </Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Creation Date</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Creation Time</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Length</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Prescriber</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedInactivePrescriptions.map((row) => (
                  <TableRow>
                    <TableCell align="center">{row.startDate}</TableCell>
                    <TableCell align="center">{row.startTime}</TableCell>
                    <TableCell align="center">{row.prescriptionLength == "3650" ? "Unlimited" : (row.prescriptionLength > (2**32 - 1440) ? (2**32 - row.prescriptionLength) + " Minutes" : row.prescriptionLength + (row.prescriptionLength > 1 ? " Days" : " Day"))}</TableCell>
                    <TableCell align="center">{row.prescriber}</TableCell>
                    <TableCell align="center">
                      {prescribingDoctor && (
                        <Button onClick={() => deletePrescription(row)}>
                          <DeleteIcon />  
                        </Button>   
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        &nbsp;
        </Grid>
      </Grid>
    </Container>
  );
};