import React, { Component } from "react";
import Products from "./Products";
import Cart from "./Cart";
import { Typography } from "@material-ui/core";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
class GetPrescriptions extends Component {
    constructor() {
      super();
  
      this.state = {
        isCartOpen: false,
        checkout: { lineItems: [] },
        products: [],
        shop: {}
      };
  
      this.handleCartClose = this.handleCartClose.bind(this);
      this.addVariantToCart = this.addVariantToCart.bind(this);
      this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
      this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
    }
  
    componentWillMount() {
      this.props.client.checkout.create().then((res) => {
        this.setState({
          checkout: res,
        });
      });
  
      this.props.client.product.fetchAll().then((res) => {
        this.setState({
          products: res,
        });
      });
  
      this.props.client.shop.fetchInfo().then((res) => {
        this.setState({
          shop: res,
        });
      });
    }
  
    addVariantToCart(variantId, quantity){
      this.setState({
        isCartOpen: true,
      });
  
      const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}]
      const checkoutId = this.state.checkout.id
  
      return this.props.client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
        this.setState({
          checkout: res,
        });
      });
    }
  
    updateQuantityInCart(lineItemId, quantity) {
      const checkoutId = this.state.checkout.id
      const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]
  
      return this.props.client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
        this.setState({
          checkout: res,
        });
      });
    }
  
    removeLineItemInCart(lineItemId) {
      const checkoutId = this.state.checkout.id
  
      return this.props.client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
        this.setState({
          checkout: res,
        });
      });
    }
  
    handleCartClose() {
      this.setState({
        isCartOpen: false,
      });
    }
  
    render() {
      return (
        <div className="App">
        <header>
        <Typography variant="h6">
               Buy Perscription Credits
              </Typography>
          {!this.state.isCartOpen &&
            <div className="App__view-cart-wrapper">
              <button className="App__view-cart" onClick={()=> this.setState({isCartOpen: true})}><ShoppingCartIcon/></button>
            </div>
          }
        
        
        <Cart
                checkout={this.state.checkout}
                isCartOpen={this.state.isCartOpen}
                handleCartClose={this.handleCartClose}
                updateQuantityInCart={this.updateQuantityInCart}
                removeLineItemInCart={this.removeLineItemInCart}
                />
        </header>
        &nbsp;
          <Products
            products={this.state.products}
            client={this.props.client}
            addVariantToCart={this.addVariantToCart}
          />
        </div>
      );
    }
}

export default GetPrescriptions;
