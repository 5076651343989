import { createSlice } from '@reduxjs/toolkit';

export const mfaSetupSlice = createSlice({
    name: "mfaSlice",
    initialState: {
        requireMFA: true,
        setupTOTP: false,
        code: '',
        username: '',
        user: null,
    },
    reducers: {
        setRequireMFA: (state, action) => {
            state.requireMFA = action.payload;
        },
        setSetupTOTP: (state, action) => {
            state.setupTOTP = action.payload;
        },
        setStoreMFA: (state, action) => {
            state.code = action.payload.code;
            state.username = action.payload.username;
            state.user = action.payload.user;
        },
    }
});

export const getRequireMFA = state => state.MFA.requireMFA;
export const getSetupTOTP = state => state.MFA.setupTOTP;
export const getStoreMFA = state => state.MFA;

export const { setRequireMFA, setSetupTOTP, setStoreMFA } = mfaSetupSlice.actions;
export default mfaSetupSlice.reducer;