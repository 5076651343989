import { Chip, withTheme } from "@material-ui/core";
import React from "react";
import Color from "./color";

const ColorChip = (props) => (
  <Color color={props.color}>
    <Chip
      {...props}
      color="primary"
      style={{ border: `1px solid ${props.theme.palette[props.color].dark}` }}
    />
  </Color>
);

export default withTheme(ColorChip);
