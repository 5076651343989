import { createSlice } from '@reduxjs/toolkit';

export const creditSlice = createSlice({
    name: "creditSupply",
    initialState: {
        credits: 0,
    },
    reducers: {
        setCredits: (state, action) => {
            state.credits = action.payload;
        },
    }
});

export const getCredits = state => state.creditSupply.credits;

export const { setCredits } = creditSlice.actions;
export default creditSlice.reducer;