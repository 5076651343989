import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useEffect, useState } from "react";
import { Loading } from ".";
import getToken from "../utils/get-token";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});
let nextToken = '';
const Unconfirmed = withAuth0(() => {
  const classes = useStyles();
  const [ makeRoleRequest, setRoleRequest ] = useState(null);
  const [data, setData] = useState("");
  const [gotData, setGotData] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();

  useEffect(() => {
    if(!makeRoleRequest){
      return;
    }

    const fetchData = async () => {
      const response = await fetch(`${apiUrl}/doctors/${makeRoleRequest.username}/confirm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
      });
      console.log(response);
      setRoleRequest(null);
    }

    fetchData()
      .then(() => {
        fetchAdminData()   
          .then((result)=> {
            const {userGroup, token} = result;
            console.log(result)
            nextToken = token;
            let currData = data;
            currData = currData.concat(userGroup)
            console.log(currData)
            setData(userGroup)
            setGotData(true)  
          });
      });

  }, [makeRoleRequest]);

  useEffect(() => {
    if(!setData){
      return;
    }
    fetchAdminData()
      .then((result)=> { 
        console.log(result)
        setData(result)
        setGotData(true)  
      });     
  }, [setData]);
 
  const fetchAdminData = async () => {
    const npiResponse = await fetch(`${apiUrl}/doctors/unconfirmed`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": await getToken(),
      },
    })
    console.log(npiResponse)
    return npiResponse.json();
  }

  if ((!gotData) || makeRoleRequest) {
    return <Loading />;
  }
    return (
      <>
        <Container className={classes.container}>
        <Typography variant="h4">Unconfirmed Doctors</Typography>
          &nbsp;
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h5">
                Doctor E-mail
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h5">
                Name
              </Typography>
            </Grid>  
            <Grid item xs={2}>
              <Typography variant="h5">
                Location
              </Typography>
            </Grid>  
            <Grid item xs={2}>
              <Typography variant="h5">
                NPI
              </Typography>
            </Grid>  
            <Grid item xs={1}>
              <Typography variant="h5">
              </Typography>
            </Grid>  
            {data && data.sort((a, b) => a.email - b.email).map(doctor => {
              return <React.Fragment key={doctor.username}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    {doctor.username}
                  </Typography>
                </Grid>
                <Grid item xs={3}>{doctor.name}</Grid>
                <Grid item xs={2}>{doctor.location}</Grid>
                <Grid item xs={2}>{doctor.npi}</Grid>
                <Grid item xs={1} style={{textAlign: 'left'}}>
                <Button
                  variant ="contained"
                  aria-label="outlined primary button group"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => {            
                    setRoleRequest({username: doctor.username})
                  }}
                  >
                    Approve
                </Button>
                </Grid>              
              </React.Fragment>;
            })}
          </Grid>
        </Container>
      </>
    );

});

export default Unconfirmed;
