import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactQueryDevtools } from "react-query-devtools";
import "./index.css";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import store from './store';
import { Provider } from 'react-redux';
import getToken from './utils/get-token';
const queryCache = new QueryCache();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <App />
        <ReactQueryDevtools />
      </ReactQueryCacheProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

store.subscribe(() => {
  //this is just a function no longer does anything... 
  //keeping it for future use.
});
