import { Auth } from 'aws-amplify';

export default async function getToken() {
    return Auth.currentSession().then((data) => {
        try{
            return "Bearer " + data.accessToken.jwtToken;
        }catch{
            return "";
        }
    });
}