import {
  Box,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useState, useEffect } from "react";
import { Loading } from ".";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useParams } from "react-router-dom";
import getToken from "../utils/get-token";
import SessionsGraph from "../charts/sessionGraph";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const apiUrl = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
  };
});

function daysBetweenDates(dateStrings) {
  const startDate = new Date(dateStrings[0]);
  const endDate = new Date(dateStrings[1]);
  const differenceInMilliseconds = endDate - startDate;
  const daysDifference = differenceInMilliseconds / (24 * 60 * 60 * 1000);

  return Math.round(daysDifference);
}

const fetchUserData = async (userId) => {
  let urlBuilder = new URL(`${apiUrl}/patients/${userId}/history`);

  let url = urlBuilder.toString();
  console.log(url);
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": await getToken(),
    },
  });

  return response.json();
};
function filterDuration(data) {
  return data.filter(item => item.durationSeconds >= 5);
}
export default function Sessions() {
  const [graphOption, setGraphOption] = useState(30);
  const [dataOption, setDataOption] = useState(false);
  const [userData, setUserData] = useState([]);
  const [showEmptySessions, setShowEmptySessions] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateRange, setDateRange] = useState({});
  const classes = useStyles();
  const shortcutsItems = [{ label: 'Reset', getValue: () => [null, null] },];
  let sortedData;

  let { userId } = useParams();

  console.log(userId);
  useEffect(() => {
    fetchUserData(userId).then((result) => {
      setUserData(result);
    })
  }, [userId, dateRange]);

  if ((!userData)) {
    return <Loading />;
  }
  let filteredData = userData;
  if (dateRange[0] && dateRange[1]) {

    filteredData = userData.filter(item => {
      let itemDate = new Date(item.startTime);  // Assuming 'date' is the attribute holding the date information
      return itemDate >= new Date(dateRange[0].$d) && itemDate <= new Date(dateRange[1].$d);
    });

    console.log(filteredData);

    console.log("flagCheck");
    sortedData = filteredData.sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });

    sortedData = sortedData.map((session) => {
      let startDate = new Date(session.startTime);
      let stopDate = new Date(session.stopTime);

      let secondsDuration = Math.round((stopDate - startDate) / 1000);

      let durationString = `${Math.floor(secondsDuration / 60)}m ${secondsDuration % 60}s`;

      return {
        date: startDate.toLocaleDateString(),
        startTime: startDate.toLocaleTimeString(),
        duration: durationString,
        endDate: stopDate.toLocaleDateString(),
        endTime: stopDate.toLocaleTimeString(),
        durationSeconds: secondsDuration,
      };
    });
  }
  else {
    console.log("flagCheck");
    sortedData = userData.sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });

    sortedData = sortedData.map((session) => {
      let startDate = new Date(session.startTime);
      let stopDate = new Date(session.stopTime);

      let secondsDuration = Math.round((stopDate - startDate) / 1000);

      let durationString = `${Math.floor(secondsDuration / 60)}m ${secondsDuration % 60}s`;

      return {
        date: startDate.toLocaleDateString(),
        startTime: startDate.toLocaleTimeString(),
        duration: durationString,
        endDate: stopDate.toLocaleDateString(),
        endTime: stopDate.toLocaleTimeString(),
        durationSeconds: secondsDuration,
      };
    });
  }
  if (!userData) {
    return <Loading />;
  }

  async function downloadSessions() {
    let rows = [
      [
        "start_date",
        "start_time",
        "end_date",
        "end_time",
        "duration",
        "duration_seconds",
      ],
    ];

    sortedData.map((session) => {
      rows.push([
        session.date,
        session.startTime,
        session.endDate,
        session.endTime,
        session.duration,
        session.durationSeconds,
      ]);
    });
    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", userId + "_sessions.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Typography variant="h5">Session History: {userId}</Typography>
      </Grid>
      <Grid item xs={3} justify="flex-end" container>
        <Button
          variant="contained"
          aria-haspopup="true"
          justify="right"
          onClick={() => downloadSessions()}
          onLoad={() => {
            let rows = [
              [
                "start_date",
                "start_time",
                "end_date",
                "end_time",
                "duration",
                "duration_seconds",
              ],
            ];
          }}
        >
          Download Session Data
        </Button>
      </Grid>

      <Grid item >
        <ButtonGroup variant="contained" aria-label="outlined primary button group" textAlign='center'>
          <Button
            variant="contained"
            aria-haspopup="true"
            justify="right"
            onClick={() => setGraphOption(30)}
            disabled={graphOption === 30}>30
          </Button>
          <Button
            variant="contained"
            aria-haspopup="true"
            justify="right"
            onClick={() => setGraphOption(60)}
            disabled={graphOption === 60}>60</Button>
          <Button
            variant="contained"
            aria-haspopup="true"
            justify="right"
            onClick={() => setGraphOption(90)}
            disabled={graphOption === 90}>90</Button>
          <Button
            variant="contained"
            aria-haspopup="true"
            justify="right"
            onClick={() => setGraphOption(100)}
            disabled={graphOption === 100}>All Time</Button>
          {dataOption ? <Button
            variant="contained"
            aria-haspopup="true"
            justify="center"
            onClick={() => setDataOption(false)}>Hide Empty Sessions</Button>
            : <></>}
          {(dataOption == false) ? <Button
            variant="contained"
            aria-haspopup="true"
            justify="right"
            onClick={() => setDataOption(true)}>Show Empty Sessions</Button>
            : <></>}
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <SessionsGraph
          sessions={filterDuration(sortedData)}
          duration={graphOption}
          showEmptyDays={dataOption} />
      </Grid>
      <Grid item xs={12}>
      </Grid>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateRangePicker']}>
            <DateRangePicker
              localeText={{ start: 'Start-Date', end: 'End-Date' }}
              onChange={(newRange) => {
                setGraphOption(daysBetweenDates(newRange));
                setDateRange(newRange);
                console.log(newRange);
              }}
              slotProps={{
                shortcuts: {
                  items: shortcutsItems,
                },
                actionBar: { actions: [] },
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Session Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box style={{ height: "40px" }} m={1} />
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Date
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Start Time
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Duration
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row) => (
                    <TableRow>
                      <TableCell align="center">{row.date}</TableCell>
                      <TableCell align="center">{row.startTime}</TableCell>
                      <TableCell align="center">{row.duration}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
