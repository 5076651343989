import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  DialogActions,
  DialogContentText
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Loading } from ".";
import DeleteIcon from '@material-ui/icons/Delete';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import getToken from "../utils/get-token";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { LoadingEmbed } from "./loading";
const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

let interactionTimer = null;
const Devices = withAuth0(({filter}) => {
  const classes = useStyles();
  const [currentDevice, setCurrentDevice] = useState(null);
  const [ requestNumber, setRequestNumber ] = useState(0);
  const [deviceData, setDeviceData] = useState([]);
  const [nextToken, setNextToken] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [pauseLoading, setPauseLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [text, setText] = useState(null);
  const [ makeChangeRequest, setChangeRequest ] = useState(null);
  const [errorText, setErrorText] = useState("Error: Something went wrong, please try again");
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();

  const DISPLAY_PAGE = 30;

  const handleClickOpen = (device) => {
    setError(false);
    setOpen(true);
    setCurrentDevice(device);
  };

  const handleClose = () => {
    setOpen(false);
    setText(null);
  };

  const fetchDevices = async() =>
  {
    let urlBuilder = new URL(`${apiUrl}/devices`);
    urlBuilder.search = new URLSearchParams({
      nextToken: nextToken ? nextToken : '',
      search: searchVal ? searchVal : '', 
    });

    let url = urlBuilder.toString();
    const deviceResponse = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": await getToken(),
      },
    });
    return deviceResponse.json();
  }

  const modifyPageIndex = (isIncrement) => {
    console.log(nextToken);
    if (!isIncrement){
      setPageIndex(pageIndex - 1);
      return;
    } else {
      setPageIndex(pageIndex + 1);
    } 
  }

  const changeSearch = async (event) => {
    setNextToken('');
    setPageIndex(0);
    setDeviceData([]);
    setNoResults(false);
    console.log(event);
    setSearchVal(event);
    if(event){
      clearTimeout(interactionTimer);
      setPauseLoading(true);
      interactionTimer = setTimeout(() => {
        setPauseLoading(false);
      }, 500);
    }
  }

  useEffect(() => {
    console.log('Clearing NextToken');
    setNextToken('');
    setPageIndex(0);
    setDeviceData([]);
    setNoResults(false);
    setSearchVal(filter);
    setPauseLoading(false);
  }, [filter])

  useEffect(() => {
    // Fetch the data using the filter and update your state accordingly
    fetchDevices(null, filter).then((devicesResult) => {
      // check for filter results to avoid race condition.
      if(filter && (devicesResult.mappedData.length > 1)) return;
      // Process and set the filtered data here
      setDeviceData(devicesResult.mappedData);
    }).catch(error => {
      // Handle any errors that might occur during the fetch
      console.error("Error fetching filtered data:", error);
      // Optionally, set an error state here to display an error message to the user
    });
  }, [filter]);  // Only re-run this effect if the filter prop changes
  
  useEffect(() => {
    if(pauseLoading){
      console.log("null return");
      console.log(deviceData.length / DISPLAY_PAGE);
      console.log(pageIndex);
      console.log(nextToken);
      console.log((nextToken !== '' || pageIndex == 0))
      console.log((pageIndex + 1) >= deviceData.length / DISPLAY_PAGE)
      return;
    }
    if ((pageIndex + 1) >= deviceData.length / DISPLAY_PAGE && (nextToken !== '' || pageIndex == 0)){
      console.log("valid return");
      fetchDevices(nextToken, searchVal).then((devicesResult)=> { 
        setNextToken(nextToken ? nextToken : '');
        let Items = devicesResult.mappedData
        let devices = [];
        let decSernum, hexSernum, formatSernum;
        for(let i=0; i < Items.length; i++) {
          decSernum = parseInt(Items[i].deviceId);
          hexSernum = ('000000000000' + decSernum.toString(16).toUpperCase()).slice(-12); //convert to hex and pad the sernum
          formatSernum = hexSernum.slice(0,2) + ":" + hexSernum.slice(2,4) + ":" + hexSernum.slice(4,6) + ":" + hexSernum.slice(6,8) + ":" + hexSernum.slice(8,10) + ":" + hexSernum.slice(10);
          devices.push({sernum: formatSernum, email: Items[i].userId, db_id: Items[i].deviceId})
        }

        function compare( a, b ) {
          if ( a.sernum < b.sernum ){
            return -1;
          }
          if ( a.sernum > b.sernum ){
            return 1;
          }
          return 0;
        }
          
        devices.sort( compare );
        setDeviceData(deviceData.concat(devices));
        if(devices.length == 0 && pageIndex == 0){
          setNoResults(true);
        }
      });
    }
  }, [pageIndex, searchVal, pauseLoading]);

  const scrollRef = useCallback((node) => {
    try{
      node.scrollIntoView();
    }catch{
    }
  }, [])

  async function processDeviceData() {
    fetchDevices()
      .then((devicesResult) => {
        console.log("setting nextToken", devicesResult.lastIndexRead);
        setNextToken(devicesResult.lastIndexRead);
        let Items = devicesResult.mappedData;
        let devices = [];
        let decSernum, hexSernum, formatSernum;
        for(let i=0; i < Items.length; i++) {
          decSernum = parseInt(Items[i].deviceId);
          hexSernum = ('000000000000' + decSernum.toString(16).toUpperCase()).slice(-12); //convert to hex and pad the sernum
          formatSernum = hexSernum.slice(0,2) + ":" + hexSernum.slice(2,4) + ":" + hexSernum.slice(4,6) + ":" + hexSernum.slice(6,8) + ":" + hexSernum.slice(8,10) + ":" + hexSernum.slice(10);
          devices.push({sernum: formatSernum, email: Items[i].userId, db_id: Items[i].deviceId});
        }

        function compare( a, b ) {
          if ( a.sernum < b.sernum ){
            return -1;
          }
          if ( a.sernum > b.sernum ){
            return 1;
          }
          return 0;
        }
          
        devices.sort( compare );
        if (filter!=undefined)return;
        setDeviceData(devices);
      });
  };
  const changeUserID = () =>{
    setError(false);
    var validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    
    if (text !== null) {
      //check for valid email format
      if(text.match(validEmailRegex)) {
        setChangeRequest({
          device: currentDevice.db_id,
          user_id: text
        });
        setRequestNumber(r => r + 1);
        handleClose();
      }
      else {
        setErrorText("Error: Invalid email format");
        setError(true);
      }
    }
    else {
      setChangeRequest({
        device: currentDevice.db_id,
        user_id: ''
      });
      setRequestNumber(r => r + 1);
      handleClose();
    }
  }

  useEffect(() => {
    if(!makeChangeRequest){
      return;
    }
    console.log(makeChangeRequest);

    const fetchData = async () => {
      let userID = makeChangeRequest.user_id;
      if(userID !== '') {
        userID.trim(); //remove any whitespace
        userID.toLowerCase(); //send to lowercase
      }
      const response = await fetch(`${apiUrl}/devices/${makeChangeRequest.device}/updateuser`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
        body: JSON.stringify({user_id: userID}),
      });
      console.log(response);

      setChangeRequest(null);
      setRequestNumber(r => r + 1);
    }

    fetchData();
  }, [makeChangeRequest]);

  useEffect(() => {
    processDeviceData();
  }, [requestNumber]);

  async function deleteDevice(deviceId) { 
    const fetchData = async () => {
      const npiResponse = await fetch(`${apiUrl}/devices/${deviceId}`, {
        method: "DELETE",
        headers: {
          "Authorization": await getToken(),
        },
      })
      return npiResponse;
    }

    if (window.confirm("Are you sure you'd like to delete device " + deviceId + "?")) {
      deviceId = deviceId.replace(/:/g, '');
      deviceId = parseInt(deviceId, 16);
      fetchData()
      .then(()=> {
        //refresh the page
        console.log(deviceId + " deleted.")
        processDeviceData()
          .then((result ) => {
            setDeviceData(result);
          })
      });
    };
  };

  if (!deviceData) {
    return <Loading />;
 }
 let dataSubset = [];
 if (deviceData){
  dataSubset = deviceData.slice(pageIndex* DISPLAY_PAGE, (pageIndex*DISPLAY_PAGE) + DISPLAY_PAGE);
}
  return (
    <>
      <Container className={classes.container}>
        <Typography variant="h4">Devices</Typography>
        <Grid item xs={12}>
            <Grid container justify="flex-end">
              <TextField
                label="Filter Devices (decimal)"
                value={searchVal}
                onChange={event => changeSearch(event.target.value)}
              />
              &nbsp;
            </Grid>
          </Grid>
        &nbsp;
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography variant="h5">
              Serial Number
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h5">
              Patient E-mail
            </Typography>
          </Grid>  
          {noResults && 
              <Grid item xs={12} style={{textAlign: 'center'}}>
                <Typography variant="h5">
                  No results
                </Typography>
              </Grid>
          }
          {!noResults && dataSubset.length == 0 && <Grid item xs={12}><LoadingEmbed/></Grid>}

          {dataSubset && dataSubset.map(device => {
            return (
              <Grid container spacing={3} key={device.db_id} id={device.db_id}>
                {open ? <Grid item xs={12} >&nbsp;</Grid> :
                <>
                  <Grid item xs={3} >
                    <div ref={device.db_id == filter ? scrollRef : null}/>
                    <Typography variant="body1" style={{backgroundColor: device.db_id == filter ? "yellow" : "transparent"}}>
                      {device.sernum}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" style={{backgroundColor: device.db_id == filter ? "yellow" : "transparent"}}>
                      {device.email}
                    </Typography>
                  </Grid> 
                  <Grid item xs={3}>
                    <Button 
                      onClick={() => deleteDevice(device.sernum)}
                      alignItems="left"
                    >
                      <DeleteIcon />  
                    </Button>      
                    <Button onClick={() => {
                        handleClickOpen(device);
                      }}
                      alignItems="left">
                      <AccountCircleIcon/>
                    </Button> 
                  </Grid>
                </>
              } 
              </Grid>
            );
          })}
        </Grid>
      </Container>
      <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
          <Grid item>
            <Button onClick={() => modifyPageIndex(false)} disabled={pageIndex === 0}><ArrowBackIosIcon/></Button>
            <Button onClick={() => modifyPageIndex(true)} disabled={nextToken == '' && ((pageIndex + 1)*DISPLAY_PAGE >= deviceData.length)}><ArrowForwardIosIcon/></Button>
          </Grid>
        </Grid>  
      <Dialog open={open} onClose={handleClose} backdr>
        <DialogTitle>Change User ID</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To associate a User ID to this device ({currentDevice?.sernum}), please enter your valid ID information here. When finished press "Set User ID".
          </DialogContentText>
          <TextField
            autoFocus
            margin="normal"
            id="addressline1"
            label="User Id"
            variant="standard"
            error={error}
            value={text}
            onChange={(e) => setText(e.target.value)}
            helperText={error ? errorText : "Ex: patient@itear.com"}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick = {() =>{
              changeUserID(); 
            }}
            >Set User ID
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default Devices;
