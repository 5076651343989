import React, { useEffect, useState } from "react";
import { Loading } from ".";
import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  Typography,
  Box,
  TextField,
  DialogActions,
  DialogContentText
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Autocomplete from '@mui/material/Autocomplete';
import getToken from "../utils/get-token";
import { LoadingEmbed } from "./loading";

const DISPLAY_PAGE = 10;

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

let interactionTimer = null;

const Doctors = withAuth0(({filter}) => {
  const classes = useStyles();
  const [ requestNumber, setRequestNumber ] = useState(0);
  const [userData, setUserData] = useState([]);
  const [nextToken, setNextToken] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [ makeRoleRequest, setRoleRequest ] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [pauseLoading, setPauseLoading] = useState(true);

  const [locationChangeOpen, setLocationChangeOpen] = useState(false);
  const [nameChangeOpen, setNameChangeOpen] = useState(false);
  const [creditsOpen, setCreditsOpen] = useState(false);
  
  const [errorText, setErrorText] = useState("Something went wrong please try again. ");
  const [openError, setErrorOpen] = useState(false);

  const [newName, setNewName] = useState('');
  const [newNPI, setNewNPI] = useState('');

  const [currentCredits, setCurrentCredits] = useState(null);
  const [newCredits, setNewCredits] = useState(0);

  const [targetUser, setTargetUser] = useState(null);
  const [addrLine1, setAddLine1] = useState(null);
  const [addrLine2, setAddLine2] = useState(null);
  const [city, setCity] = useState(null);
  const [usState, setUSState] = useState(null);
  const [zipCode, setZipCode] = useState(null);

  const [makeLocationRequest, setLocationRequest ] = useState(null);

  const fullAddr = `${addrLine1} ${addrLine2} ${city}, ${usState}, ${zipCode}`
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();
  const countries = [
    {label: 'United States', id: 1 },
    {label: 'United Kingdom', id: 2 },
    {label: 'Canada', id: 3 },
    {label: 'Mexico', id: 4 },
  ]

  const fetchDoctorData = async () => {
    let urlBuilder = new URL(`${apiUrl}/doctors`);
    urlBuilder.search = new URLSearchParams({
      nextToken: nextToken ? nextToken : '',
      search: searchVal ? searchVal : ''
    });

    let url = urlBuilder.toString();
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": await getToken(),
      },
    })
    console.log(response)
    return response.json();
  }

  const changeSearch = async (event) => {
    setNextToken('');
    setPageIndex(0);
    setUserData([]);
    setNoResults(false);
    setSearchVal(event);
    if(event){
      clearTimeout(interactionTimer);
      setPauseLoading(true);
      interactionTimer = setTimeout(() => {
        setPauseLoading(false);
      }, 500);
    }
  }

  useEffect(() => {
    setNextToken('');
    setPageIndex(0);
    setUserData([]);
    setNoResults(false);
    setSearchVal(filter);
    setPauseLoading(false);
  }, [filter])

  useEffect(() => {
    fetchDoctorData(nextToken, searchVal).then((result)=> { 
      const {userGroup, token} = result;
      console.log(result)

      setNextToken(token ? token : '');
      if(filter!=undefined)return;
      setUserData(userGroup);
      if(userGroup.length == 0 && pageIndex == 0){
        setNoResults(true);
      }
    });
}, [makeRoleRequest]);

  useEffect(() => {
    if(pauseLoading){
      return;
    }

    if ((pageIndex + 1) >= userData.length / DISPLAY_PAGE && (nextToken !== '' || pageIndex == 0)){
      fetchDoctorData(nextToken, searchVal).then((result)=> { 
        const {userGroup, token} = result;
        console.log(result)

        setNextToken(token ? token : '');
        setUserData(userData.concat(userGroup));
        if(userGroup.length == 0 && pageIndex == 0){
          setNoResults(true);
        }
      });
    }
  }, [pageIndex, searchVal, pauseLoading]);

  const modifyPageIndex = (isIncrement) => {
    console.log(nextToken);
    if (!isIncrement){
      setPageIndex(pageIndex - 1);
      return;
    } else {
      setPageIndex(pageIndex + 1);
    } 
  }

  const handleClickEditDetailsOpen = (username, currentName, currentNPI) => {
    setNewName(currentName || '');
    setNewNPI(currentNPI || '');
    setNameChangeOpen(true);
    setTargetUser(username);
  };

  const handleClickLocationChangeOpen = (username) => {
    setLocationChangeOpen(true);
    setTargetUser(username)
  };

  const handleClickSetCreditsOpen = (username) => {
    setCreditsOpen(true);
    setCurrentCredits(null);
    setNewCredits(0);
    setTargetUser(username);

    let creditFetch = async () => {
      const credits = (await (await fetch(`${apiUrl}/doctors/${encodeURIComponent(username)}/credits`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
      })).json()).credits;

      setCurrentCredits(credits);
      setNewCredits(credits);
    }

    creditFetch();
  };

  const handleClose = () => {
    setLocationChangeOpen(false);
    setNameChangeOpen(false);
    setCreditsOpen(false);
    setErrorOpen(false);
  };

  const handleNameChangeRequest = async () => {
    handleClose();

    const fetchData = async () => {
      const profile = (await (await fetch(`${apiUrl}/doctors/${encodeURIComponent(targetUser)}/npi`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
      })).json())[0];

      const response = await fetch(`${apiUrl}/doctors/${targetUser}/npi`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
        body: JSON.stringify({
          npi: newNPI,
          doctor_name: newName.toUpperCase(),
          doctor_location: profile.location,
        }),
      });
      
      if(response.ok){
        // Update UI
        userData.find((u) => u.username == targetUser).name = newName.toUpperCase();
        userData.find((u) => u.username == targetUser).npi = newNPI;
        setUserData(userData.slice());
      }
    }

    try{
      await fetchData();
    }catch(e){
      setErrorText(e.toString())
      setErrorOpen(true);
    }
  };

  const handleSetCreditsRequest = async () => {
    handleClose();
    
    const fetchData = async () => {
      const response = await fetch(`${apiUrl}/doctors/${encodeURIComponent(targetUser)}/updatecredits`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
        body: JSON.stringify({
          quantity: newCredits - currentCredits,
        }),
      });
      
      if(!response.ok){
        throw("Error updating credits");
      }
    }

    try{
      await fetchData();
    }catch(e){
      setErrorText(e.toString())
      setErrorOpen(true);
    }
  };

  const handleLocationRequest = () =>{
    setLocationRequest({
      username: targetUser,
      location: fullAddr
    });
  }

  const handleLocationCheck = () => {
    if((addrLine1 != null)&& (city!= null) && (usState!= null) && (zipCode!= null)){
      console.log("valid filled", addrLine1)
      return true
    }
    else return false
  }

  useEffect(() => {
    if(!makeLocationRequest){
      return;
    }
    const fetchData = async () => {
      let location;
      location = {doctor_location: fullAddr.replaceAll('null', '')};
      const response = await fetch(`${apiUrl}/doctors/${makeLocationRequest.username}/updatelocation`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
        body: JSON.stringify(location),
      });
      console.log(response)

      setLocationRequest(null);
      setRequestNumber(r => r + 1);
    }

    fetchData();
  }, [makeLocationRequest]);

  const updateRole = async (newRole) => {
    const response = await fetch(`${apiUrl}/users/${makeRoleRequest.username}/updaterole`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": await getToken(),
      },
      body: JSON.stringify({userGroup: newRole}),
    });

    return response.json();
  }

  const updatePrescriberPrivileges = async (prescriberStatus) => {
    const npiResponse = await fetch(`${apiUrl}/doctors/${makeRoleRequest.username}/prescriber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": await getToken(),
      },
      body: JSON.stringify({prescriber: prescriberStatus}),
    })
    console.log(npiResponse)
    return npiResponse.json();
  }

  useEffect(() => {
    if(!makeRoleRequest){
      return;
    }

    const fetchData = async () => {
      if (makeRoleRequest.role === "Admin"){
        console.log("set role request: " + makeRoleRequest.role)
        updateRole("Admin").then((response)=> {
          console.log(response);
          setRoleRequest(null);
          setRequestNumber(r => r + 1);
        });
      }
      else if (makeRoleRequest.role === "Patient"){
        console.log("set role request: " + makeRoleRequest.role)
        updateRole("Patient").then((response)=> {
          console.log(response);
          setRoleRequest(null);
          setRequestNumber(r => r + 1);
        });
      }
      else if (makeRoleRequest.role === "PrescribingDoctor"){
        console.log("set role request: " + makeRoleRequest.role)
        updatePrescriberPrivileges(true).then((response)=> {
          console.log(response);
          setRoleRequest(null);
          setRequestNumber(r => r + 1);
        });
      }
      else if (makeRoleRequest.role === "PrescribingDoctorRemove"){
        console.log("set role request: " + makeRoleRequest.role)
        updatePrescriberPrivileges(false).then((response)=> {
          console.log(response);
          setRoleRequest(null);
          setRequestNumber(r => r + 1);
        });
      }
      else {
        console.log("set role request: " + makeRoleRequest.role)
        updateRole("Manufacturer").then((response)=> {
          console.log(response);
          setRoleRequest(null);
          setRequestNumber(r => r + 1);
        });
      }
    }

    fetchData();
  }, [makeRoleRequest]);

  async function deleteAccount(username) {
    const fetchData = async () => {
      const npiResponse = await fetch(`${apiUrl}/users/${username}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
      })
      console.log(npiResponse)
      return npiResponse.json();
    }

    fetchData()
      .then(()=> {
        setRequestNumber(r => r + 1);
      });
  };

  const IsolatedMenu = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
   
    return(
    <React.Fragment>
      <Button
      variant ="contained"
      aria-label="outlined primary button group"
      aria-controls="simple-menu"
      aria-haspopup="true"
      onClick={event => setAnchorEl(event.currentTarget)}
      >
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => {
            setAnchorEl(null);
            handleClickEditDetailsOpen(props.doctor.username, props.doctor.name, props.doctor.npi);

        }}>Edit Details</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            handleClickLocationChangeOpen(props.doctor.username);

        }}>Change Location</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.doctor.username,
              role: "PrescribingDoctor"
            })
        }}>Grant Prescribing Privileges</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.doctor.username,
              role: "PrescribingDoctorRemove"
            })
        }}>Remove Prescribing Privileges</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            handleClickSetCreditsOpen(props.doctor.username);
        }}>Set Credits</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.doctor.username,
              role: "Admin"
            })
        }}>Make Admin</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.doctor.username,
              role: "Patient"
            })
        }}>Make Patient</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.doctor.username,
              role: "Manufacturer"
            })
        }}>Make Manufacturer</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            deleteAccount(props.doctor.username);
        }}>Delete Account</MenuItem>
        <MenuItem onClick={() => {
            window.location.href = `/transactions/${props.doctor.username}`;
        }}>View Account Transactions</MenuItem>
      </Menu>
  
    </React.Fragment>
    )
  }

  let dataSubset = [];
  if (userData){
    console.log(userData);
    dataSubset = userData.slice(pageIndex* DISPLAY_PAGE, (pageIndex*DISPLAY_PAGE) + DISPLAY_PAGE)
  }

  return (
      <>
        <Container className={classes.container}>
          &nbsp;
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h4">Doctors</Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container justify="flex-end">
                <TextField
                  label="Filter Email"
                  value={searchVal}
                  onChange={event => changeSearch(event.target.value)}
                />
                &nbsp;
                {/* <Button variant="contained" onClick={() => searchUser()}>Search</Button> */}
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="h5">
                Doctor E-mail
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h5">
                Name
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h5">
                Location
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h5">
                NPI
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h5">
                Prescribing Doctor
              </Typography>
            </Grid>
            {noResults && 
              <Grid item xs={12} style={{textAlign: 'center'}}>
                <Typography variant="h5">
                  No results
                </Typography>
              </Grid>
            }
            {!noResults && dataSubset.length == 0 && <Grid item xs={12}><LoadingEmbed/></Grid>}
            {(userData) && dataSubset.sort((a, b) => a.username - b.username).map(doctor => {
              return <React.Fragment key={doctor.username}>
                <Grid item xs={5}>
                  <Typography variant="body1">
                    {doctor.username}
                  </Typography>
                </Grid>
                <Grid item xs={2}>{doctor.name}</Grid>
                <Grid item xs={2}>{doctor.location}</Grid>
                <Grid item xs={1}>{doctor.npi}</Grid>
                <Grid item xs={1}>{doctor.prescriber ? "Yes" : "No"}</Grid>
                <Grid item xs={1} style={{textAlign: 'left'}}>
                  <IsolatedMenu doctor={doctor}/>
                </Grid>
              </React.Fragment>;
            })}
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
              <Grid item>
                <Button onClick={() => modifyPageIndex(false)} disabled={pageIndex === 0}><ArrowBackIosIcon/></Button>
                <Button onClick={() => modifyPageIndex(true)} disabled={nextToken == '' && ((pageIndex + 1)*DISPLAY_PAGE >= userData.length)}><ArrowForwardIosIcon/></Button>
              </Grid>
            </Grid>       

          </Grid>
        </Container>
        <Dialog open={nameChangeOpen} onClose={handleClose}>
          <DialogTitle>Edit Doctor Details</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="normal"
              id="newName"
              label="New Name"
              type="string"
              variant="standard"
              onChange={(e) => setNewName(e.target.value)}
              helperText="Name or Practice Name"
              value={newName}
              fullWidth={true}
              required
            />
            <TextField
              margin="normal"
              id="newNPI"
              label="New NPI"
              type="string"
              variant="standard"
              onChange={(e) => setNewNPI(e.target.value)}
              helperText="NPI"
              value={newNPI}
              fullWidth={true}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleNameChangeRequest} disabled={newName.trim().length <= 3 || newNPI.trim().length <= 5}>
              Set Details
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={creditsOpen} onClose={handleClose}>
          <DialogTitle>Set Credits</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Current credits: {currentCredits === null ? 'Loading...' : currentCredits}
            </DialogContentText>
            <TextField
              autoFocus
              margin="normal"
              id="newName"
              label="Credits"
              type="number"
              variant="standard"
              onChange={(e) => setNewCredits(e.target.value)}
              helperText="Credits"
              value={newCredits}
              fullWidth={true}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSetCreditsRequest}>
              Set Credits
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={locationChangeOpen} onClose={handleClose}>
          <DialogTitle>Change Location</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To change your location information, please enter your valid location information here. When finished press "Set Location".
            </DialogContentText>
            <TextField
              autoFocus
              margin="normal"
              id="addressline1"
              label="Street Address"
              type="location"
              variant="standard"
              onChange={(e) => setAddLine1(e.target.value)}
              helperText="123 Cherry St."
              required
            />
            <TextField
              autoFocus
              margin="normal"
              id="addressline2"
              label="Address Line 2"
              type="location"
              variant="standard"
              helperText="apt 1"
              onChange={(e) => setAddLine2(e.target.value)}
            />
            <TextField
              autoFocus
              margin="normal"
              id="city"
              label="City"
              type="location"
              variant="standard"
              onChange={(e) => setCity(e.target.value)}
              helperText="San Diego"
              required
            />
            <TextField
              autoFocus
              margin="normal"
              id="state"
              label="State"
              type="location"
              variant="standard"
              onChange={(e) => setUSState(e.target.value)}
              helperText="CA"
              required
            />
            <TextField
              autoFocus
              margin="normal"
              id="zipcode"
              label="ZipCode"
              type="location"
              variant="standard"
              onChange={(e) => setZipCode(e.target.value)}
              helperText="12345"
              required
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              defaultValue={countries[0]}
              options={countries}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Country" />}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick = {() =>{
              let validDate = handleLocationCheck();
              if(validDate){
                handleLocationRequest();
                handleClose();
              }
              else{
                setErrorText("Make sure all required fields are filled out")
                setErrorOpen(true);
              }
            }}
            >Set Location
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openError} onClose={handleClose}>
        <DialogTitle>{errorText}</DialogTitle>
        
        </Dialog>
      </>
    );
  }
);

export default Doctors;
