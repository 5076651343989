import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Loading from "./loading";
import getToken from "../utils/get-token";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

const DoctorSearch = ({userId, setAssignDoctorSuccess}) => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [checkNPI, setCheckNPI] = useState(false);
  const [doctorList, setDoctorList] = useState(null);
  const [NPI, setNPI] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [doctorError, setDoctorError] = useState(false); 
  const nameErrorText = "Last name is required";
  const doctorErrorText = "Cannot find doctor, please try again";
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();

  useEffect(() => {
    if(!checkNPI){
      return;
    }
    setNameError(false);
    setDoctorError(false);
    if(lastName) {
      const fetchData = async () => { 
        const npiResponse = await fetch(`${apiUrl}/npi/internal/search`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": await getToken(),
          },
          body: JSON.stringify({
            lastName: lastName.toUpperCase()
          })
        });
          return npiResponse.json();
        }
      
      fetchData()
        .then((result) => {
          console.log(result)
          if(result.length > 0)
          {
            const uniqueIds = [];

            const uniqueDoctors = result.filter(element => {
              let isDuplicate = false; 
              let doctorInfo = [element.location, element.name];
                
              for (let i = 0; i < uniqueIds.length; i++) {  
                isDuplicate = (uniqueIds[i].length == doctorInfo.length) && uniqueIds[i].every(function(element, index) {
                  return element === doctorInfo[index]; 
                });
                if(isDuplicate) {
                  break;
                }
              }

              if (!isDuplicate) {
                uniqueIds.push([element.location, element.name]);
                return true;
              }
              return false;
            });

            setDoctorList(uniqueDoctors);
          }
          else{
            setDoctorError(true);
            setDoctorList(null);
            console.log(result)
          }
        });
        setCheckNPI(false);
    }
    else {
      if(!lastName) {
        setNameError(true);
      }
      setCheckNPI(false);
    } 
  }, [checkNPI]);

  useEffect(() => {
    if(!NPI){
      return;
    }
    console.log(NPI)
    const fetchData = async () => {
      const npiResponse = await fetch(`${apiUrl}/patients/${userId}/assigndoctor`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
        body: JSON.stringify({NPI: NPI}),
      })
      console.log(npiResponse);
    }
    fetchData()
      .then(() => {
        //Set state here to tell us that this was successful and close the other dialog
        setAssignDoctorSuccess(true);
      });
  }, [NPI]);

  if(checkNPI){
    return <Loading/>;
  }

  return (
      <>
        <Container className={classes.container}>
          &nbsp;
          <Grid item style={{textAlign: 'center'}} xs={12}>
            <Typography variant="h4">Search for a Doctor</Typography>
          </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} id="Page Title">
              </Grid>
              <Grid item xs={6} id="First Name">
                <TextField 
                  id="standard-basic"
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)} />    
              </Grid>
              <Grid item xs={6} id="Last Name">
                <TextField 
                  id="standard-basic"
                  label="Last Name"
                  error={nameError}
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)} />    
              </Grid>
              {nameError && (          
                <>      
                  <Grid item xs={6}>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ color: 'red', fontSize: '12px' }}>{nameErrorText}</Typography>
                  </Grid>
                </>
              )}
              <Grid item style={{textAlign: 'center'}} xs={12}>
                <Button 
                  variant='contained' 
                  style={{marginTop: '16px'}} 
                  onClick={() => setCheckNPI(true)}
                >
                  {doctorList ? "Search Again" : "Search"}
                </Button>
              </Grid>
              {doctorError && (
                <Grid item style={{textAlign: 'center'}} xs={12}>
                  <Typography style={{ color: 'red', fontSize: '12px', marginTop: '16px' }}>{doctorErrorText}</Typography>
                </Grid>
              )}
              {doctorList && doctorList.map(doctor => {
                return <React.Fragment key={doctor.number}>
                  <Grid item xs={12}>
                    <hr />
                  </Grid>
                  <Grid item xs={6}>
                  <Typography variant="body1" style={{
                    marginTop: '20px'
                  }}>
                    Name: {doctor.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{
                    marginTop: '20px'
                  }}>
                    Location: {doctor.location}
                  </Typography>
                </Grid>
                <Grid item style={{textAlign: 'center'}} xs={12}>
                  <Button 
                    variant='contained' 
                    style={{marginTop: '16px'}} 
                    onClick={() => setNPI((doctor.npi).toString())}
                  >
                    Select Doctor
                </Button>
                </Grid>
                </React.Fragment>
              })
              }
            </Grid>
        </Container>
      </>
    );
};

export default DoctorSearch;