import React, { useState } from "react";
import CustomSignUp from "./CustomSignUp";
import ResetPassword from "./ResetPassword";
import {
  Avatar, 
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  CssBaseline,
  ButtonGroup,
} from "@material-ui/core";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {Auth} from 'aws-amplify';
import { setRequireMFA, setSetupTOTP, setStoreMFA } from './MFA-setup-slice';
import { useDispatch } from 'react-redux';
import SetupTOTP from "./SetupTOTP";
import TOTP from "./TOTP";
import Copyright from "./copyright";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const is_prod = process.env.REACT_APP_IS_PROD === 'true';

const theme_blue = {
  light: "#ffffff",
  main: "#2c2c34", //changed
  dark: "#3c3c44",
  contrastText: "#44d3b4", //changed
};

const theme_black = {
  light: "#2c2c2c",
  main: "#000000",
  dark: "#000000",
  contrastText: "#ffffff",
};

const theme_orange = {
  light: "#ff935f",
  main: "#fc6132",
  dark: "#c22d01",
  contrastText: "#FFFFFF",
};

const theme = createMuiTheme({
  props: {
    // Name of the component
    MuiButton: {
      // The properties to apply
      //variant: 'contained'
    },
  },
  palette: {
    primary: is_prod ? theme_blue : theme_orange,
    secondary: theme_black,
    orange: theme_orange,
  },
});

const SignIn = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showSignIn, setShowSignIn] = useState(true);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [user, setUser] = useState('');
  const [originalPassword, setOriginalPassword] = useState('');
  const [group, setGroup] = useState('Patient');
  const [code, setCode] = useState(null);
  const [showTOTPSetup, setShowTOTPSetup] = useState(false);
  const [showTOTP, setShowTOTP] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [error, setError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [signUpConfirmation, setSignInConfirmation] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const sendConfirmEmail = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/users/${username}/confirmationemail`, {
      method: "POST",
      headers: {
       "Content-Type": "application/json",
      },
    })
    console.log(response);
    console.log(response.json());
  }


  function handleChangePasswordSubmission(evt) {
    evt.preventDefault()
    changePassword(password);
    console.log("handle confirmation email flag", username)
    sendConfirmEmail(evt);
    
  }

  async function changePassword(newPassword) {
    setPasswordError(false);
    setError(false);
    try {
      const new_password = newPassword;
      if(new_password === originalPassword) {
        setPasswordError(true);
        setPasswordErrorText("Error: Your new password must be unique from your old password");
      }
      else{
        await Auth.completeNewPassword(user, new_password, user.challengeParam.requiredAttributes).then((result)=> {
          dispatch(setRequireMFA(false));
          if(signUpConfirmation){
            
            window.location.reload();}
        })
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        setError(true);
        setErrorText("Error: Your account must be approved before you can log in");
      } else if (err.code === 'NotAuthorizedException') {
          // The error happens when the incorrect password is provided
          console.log(err)
          setPasswordError(true);
          setPasswordErrorText("Error: Incorrect Password");
      } else if(err.code === 'InvalidPasswordException') {
        console.log(err)
        setPasswordError(true);
        setPasswordErrorText("Error: Password must be longer than 8 characters");
      } else {
          console.log(err)
          setError(true);
          setErrorText("Error: Please try again");
      }
    }
  }

  async function signIn(e) {
    e.preventDefault();
    setUsernameError(false);
    setPasswordError(false);
    setError(false);
    if(username && password) {
      try {
        await Auth.signIn(username, password).then((result) => {
          setUser(result);
          if (result.challengeName === 'SOFTWARE_TOKEN_MFA') {
            console.log("TOTP MFA challenge")
            dispatch(setRequireMFA(true));
            openTOTP();
          } 
          else if(result.challengeName == "NEW_PASSWORD_REQUIRED") {
            setUser(result);
            console.log("new password required")
            setOriginalPassword(password);
            openChangePassword();
          }
          else if (result.challengeName === 'MFA_SETUP') {
            console.log("need to set up TOTP")
            Auth.setupTOTP(result).then((code) => {
              setCode(code);
              openTOTPSetup();
            });
          }
          else { 
            let role = (Auth.user.signInUserSession.idToken.payload["cognito:groups"]).toString();
            let findAdmin = role.indexOf("Admin");
            //If they haven't logged in since 2FA was required and they're an admin, set them up with TOTP
            if(findAdmin !== -1) {
              console.log("haven't logged in since 2FA was required, need to set up TOTP")
              dispatch(setSetupTOTP(true));
              Auth.setupTOTP(result).then((code) => {
                dispatch(setStoreMFA({code: code, username: username, user: result}));
              });
            }
            else {
              //If they're not an admin, don't require 2FA
              dispatch(setRequireMFA(false));
            }
          }
        });
      } catch (err) {
          if (err.code === 'UserNotConfirmedException') {
              setErrorText("Error: Account creation incomplete, return to Sign Up");
              setError(true);
          } else if (err.code === 'InvalidParameterException' && (err.message).includes('userName')) {
              // The error happens when invalid characters (usually a space) are provided
              console.log(err)
              setErrorText("Error: Incorrect username");
              setUsernameError(true);
              setError(true);
          } else if (err.code === 'NotAuthorizedException') {
            // The error happens when the incorrect password is provided
            if(password.length < 8){
              setErrorText("Error: Your password must be 8 characters or longer");
              console.log(err)
              setError(true);
            }
            else{
              setErrorText("Error: Incorrect username or password");
              setError(true);
            }
          } else if (err.code === 'UserNotFoundException') {
              // The error happens when the supplied username/email does not exist in the Cognito user pool
              setErrorText("Error: User does not exist");
              setError(true);
          } else if (err.code === 'InternalErrorException') {
              // The error happens when the application cannot successfully communicate with the server
              setErrorText("Error: Internal server error");
              setError(true);
          } else if (err.code === 'NetworkError') {
            // The error happens when the application cannot successfully communicate with the server
              setErrorText("Error: Network error");
              setError(true);
          } else {
              console.log(err)
              setErrorText("Error: Something went wrong");
              setError(true);
          }
      }
    }
    else {
      if(!username && !password) {
        setUsernameError(true);
        setPasswordError(true);
        setErrorText("Error: Username and password are required");
      }
      else if(!username) {
        setUsernameError(true);
        setErrorText("Error: Username is required");
      }
      else if(!password) {
        setPasswordError(true);
        setErrorText("Error: Password is required");
      }
      setError(true);
    }
  }

  const openSignUp = (newGroup) => {
    setShowSignIn(!showSignIn);
    setShowSignUp(!showSignUp);
    setGroup(newGroup);
  }

  const openReset = () => {
    setShowSignIn(!showSignIn);
    setShowResetPassword(!showResetPassword);
  }

  const openTOTPSetup = () => {
    setShowSignIn(!showSignIn);
    setShowTOTPSetup(!showTOTPSetup);
  }

  const openTOTP = () => {
    setShowSignIn(!showSignIn);
    setShowTOTP(!showTOTP);
  }

  const openChangePassword = () => {
    setShowSignIn(!showSignIn);
    setShowChangePassword(!showChangePassword);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }

  return (
    <div>
      {showSignIn && (
        <ThemeProvider theme={theme}>
          <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
            <CssBaseline />
            <div>
              <Grid item>
                <Typography component="h1" variant="h5" align="center">
                  Olympic Ophthalmics Account Login         
                </Typography>
              </Grid>
              <Grid item align="center">
                <Avatar>
                  <LockOutlinedIcon />
                </Avatar>
              </Grid>
              <Grid item>
                <form noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Email Address"
                    name="username"
                    autoComplete="email"
                    autoFocus
                    error={usernameError}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    error={passwordError}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                  {(
                    <Grid item>
                      <Typography style={{ color: 'red', fontSize: '15px' }}>{errorText}&nbsp;</Typography>
                    </Grid>
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={(e)=>signIn(e)}
                  >
                    Sign In
                  </Button>
                  <Box mt={1}>
                  <ButtonGroup fullWidth variant="text" color="primary" aria-label="text primary button group">
                    <Button onClick={() => openSignUp("Patient")}>Patient Sign Up</Button>
                    <Button onClick={() => openSignUp("Unconfirmed")}>Doctor Sign Up</Button>
                  </ButtonGroup>
                  </Box>
                  <Box>
                    <Button
                      type="button"
                      fullWidth
                      color="primary"
                      onClick={() => openReset()}
                    >
                      Forgot Password?
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      type="button"
                      fullWidth
                      color="primary"
                      href="https://olympicophthalmics.com/troubleshooting/"
                    >
                      Troubleshooting Guide
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      type="button"
                      fullWidth
                      color="primary"
                      href="https://itear100.store/"
                    >
                      iTEAR100 Store
                    </Button>
                  </Box>
                </form>
             </Grid>
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Grid>
        </ThemeProvider>)}
      {showSignUp&& <CustomSignUp group={group}/>}
      {showResetPassword&& <ResetPassword /> }
      {showChangePassword && (
        <ThemeProvider theme={theme}>
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
          <CssBaseline />
          <div>
            <Grid item>
              <Typography component="h1" variant="h5" align="center">
                Change Password         
              </Typography>
            </Grid>
            <Grid item align="center">
              <Avatar>
                <LockOutlinedIcon />
              </Avatar>
            </Grid>
            <Grid item>
              <form noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="new_password"
                  label="Password"
                  type="password"
                  id="new_password"
                  autoComplete="current-password"
                  error={passwordError}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {passwordError && (
                    <Grid item>
                      <Typography style={{ color: 'red', fontSize: '15px' }}>{passwordErrorText}</Typography>
                    </Grid>
                )}
                {error && (
                  <Grid item>
                    <Typography style={{ color: 'red', fontSize: '15px' }}>{errorText}</Typography>
                  </Grid>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleChangePasswordSubmission(e)}
                >
                  Sign In
                </Button>
              </form>
            </Grid>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Grid>
      </ThemeProvider>)}
      {showTOTPSetup && <SetupTOTP code={code} username={username} user={user}/>}
      {showTOTP && <TOTP user={user}/>}
    </div>
  );
};

export default SignIn;