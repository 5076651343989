import {
    Box,
    Grid,
    Typography,
    Button,Accordion, AccordionSummary
  } from "@material-ui/core";
  import makeStyles from "@material-ui/core/styles/makeStyles";
  import 'date-fns';
  import React, {useState, useEffect} from "react";
  import { Loading } from ".";
  import Table from '@material-ui/core/Table';
  import TableBody from '@material-ui/core/TableBody';
  import TableCell from '@material-ui/core/TableCell';
  import TableContainer from '@material-ui/core/TableContainer';
  import TableHead from '@material-ui/core/TableHead';
  import TableRow from '@material-ui/core/TableRow';
  import Paper from '@material-ui/core/Paper';
  import { useParams } from "react-router-dom";
  import AccordionDetails from '@mui/material/AccordionDetails';
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
  import getToken from "../utils/get-token";
  
  const apiUrl = process.env.REACT_APP_API_URL;

  const useStyles = makeStyles((theme) => {
    return {
      container: {
        marginBottom: theme.spacing(2),
      },
      table: {
          minWidth: 650,
      },
      ellipsis: {
        maxWidth: '500px', // percentage also works
      },
    };
  });

 const fetchTransactionData = async (userId) => {
  let urlBuilder = new URL(`${apiUrl}/doctors/${userId}/transactionhistory`);

  let url = urlBuilder.toString();
  console.log(url)
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": await getToken(),
    },
  })

  return response.json();
}
  export default function TransactionsPage() {
      const classes = useStyles();
      let { userId } = useParams();
      const [transactionData, setTransactionData] = useState([]);

      useEffect(() => {
        fetchTransactionData(userId).then((result)=> { 
          setTransactionData(result);
    })}, []);

      if ((!transactionData)) {
        return <Loading />;
      }
      let filteredData;
      console.log(transactionData);
        filteredData = transactionData;
      async function downloadTransactions() {
        let rows = [['timestamp', 'user_id', 'productIds', 'quantity', 'credits','pcredits',  'ShopifyInfo']];

        filteredData.map(row => {
          rows.push([row.timestamp, row.username, row.productIds, row.quantity, row.creditsBefore, row.creditsAfter, row.transactionDetails])
        })
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", userId + "_sessions.csv");
        document.body.appendChild(link); // Required for FF
          
        link.click();
      };
      return (
        <Grid container>
            <Grid item xs={9}>
          <Typography variant="h4">Transaction History: {userId}</Typography>
        </Grid>
          <Grid item xs={3} justify='flex-end' container>
            <Button
                    variant ="contained"
                    aria-haspopup="true"
                    justify="right"
                    onClick={() => downloadTransactions()}
                >
                Download Transaction Data
            </Button>
        </Grid>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Timestamp</TableCell>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Item ID</TableCell>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Item Quantity</TableCell>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Credit Total Before</TableCell>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Credit Total After</TableCell>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Transaction Details</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(filteredData.reverse()).map((row) => ( 
                            <TableRow>
                                <TableCell align="center">{new Date(row.timestamp).toLocaleDateString()}<br/>{new Date(row.timestamp).toLocaleTimeString()}</TableCell>
                                <TableCell align="center">{row.productIds}</TableCell>
                                <TableCell align="center">{row.quantity}</TableCell>
                                <TableCell align="center">{row.creditsBefore}</TableCell>
                                <TableCell align="center">{row.creditsAfter}</TableCell>
                                <TableCell align="center">
                                    <Accordion className={classes.ellipsis}> 
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                            <Typography color= "secondary" className={classes.ellipsis}> Transaction Details</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {row.transactionDetails}
                                        </AccordionDetails>
                                    </Accordion>
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
          </Grid>
      );
    }