import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio, 
  Box,
  CircularProgress,
  Dialog,
} from "@material-ui/core";
import 'date-fns';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Alert from './snackbar-message';
import SearchIcon from '@mui/icons-material/Search';
import DoctorSearch from '../components/doctorsearch';
import getToken from "../utils/get-token";
import { useHistory } from "react-router-dom";

const Profile = withAuth0(() => {
  const [patientName, setPatientName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [preferredMethod, setPreferredMethod] = useState("email");
  const [nameError, setNameError] = useState(false);
  const [saved, setSaved] = useState(false);
  const [hasDoctor, setHasDoctor] = useState(false);
  const [doctorNPI, setDoctorNPI] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [assignDoctorSuccess, setAssignDoctorSuccess] = useState(false);
  const nameErrorText = "Name is required";
  let { userId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();
  const history = useHistory();

  async function saveChanges() {
    setSaved(false);
    setAssignDoctorSuccess(false);
    if(patientName) {
      const updateResponse = await fetch(`${apiUrl}/patients/${userId}/profile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
        body: JSON.stringify({
          name: patientName,
          address: address,
          phone_number: phoneNumber,
          preferred_contact_method: preferredMethod,
        }),
      });
      console.log(updateResponse);
      if(updateResponse.status === 200) {
        setSaved(true);
      }
    }
    else {
      setNameError(true);
    }
  }

  const fetchProfileData = async () => {
    const profileResponse = await fetch(`${apiUrl}/patients/${userId}/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": await getToken(),
      },
    })
    return profileResponse.json();
  }

  const processProfileData = async () => {
    setPatientName("");
    setAddress("");
    setPhoneNumber("");
    setPreferredMethod("email");
    setDoctorNPI("");
    setDoctorName("");
    setHasDoctor(false);

    try {
      await fetchProfileData()
      .then((data)=> { 
        console.log(data)
        if(data.length > 0) {
          let patientInfo = data[0];
          let patientDoctorNPI = data[0]["doctor_npi"];

          setPatientName(patientInfo["name"] || "");
          setAddress(patientInfo["address"] || "");
          setPhoneNumber(patientInfo["phone_number"] || "");
          setPreferredMethod(patientInfo["preferred_contact_method"] || "email");

          if(patientDoctorNPI !== "" && patientDoctorNPI !== "0") {
            setDoctorNPI(patientInfo["doctor_npi"]);
            setDoctorName(patientInfo["doctor_name"]);
            setHasDoctor(true);
          }
          else {
            setHasDoctor(false);
          }
          setLoading(false);
        }
      });
    }
    catch { //We need to push the doctor back to the /patients page after they assign a new doctor to a patient
      history.push("/patients");
    }

  }

  useEffect(() => {
    processProfileData();
  }, [userId]);

  useEffect(() => {
    if(assignDoctorSuccess) {
      handleClose();
    }
  }, [assignDoctorSuccess]);

  const handleClose = () => {
    setOpen(false);
    processProfileData();
  };

  const handleOpen = () => {
    setAssignDoctorSuccess(false);
    setSaved(false);
    setOpen(true);
  };

  return(
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap'}}>
        &nbsp;
        <Grid item xs={12}>
          <Typography variant='h5' align='center'>Patient Information</Typography>
        </Grid>
        <TextField
          margin="normal"
          variant="outlined"
          required
          fullWidth
          id="sign-up-username"
          label="Patient Username"
          name="patientUsername"
          type="name"
          value={userId}
          inputProps={
            { readOnly: true, }
          }
        />
        <TextField
          margin="normal"
          variant="outlined"
          required
          fullWidth
          id="sign-up-name"
          label="Patient Name"
          name="patientName"
          autoComplete="name"
          type="name"
          value={patientName || ""}
          error={nameError}
          onChange={(e) => setPatientName(e.target.value)}
        />
        {nameError && (
          <Grid item>
            <Typography style={{ color: 'red', fontSize: '15px' }}>{nameErrorText}</Typography>
          </Grid>
        )}
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          id="sign-up-number"
          label="Phone Number"
          name="patientName"
          autoComplete="phone"
          type="phone"
          value={phoneNumber || ""}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          id="sign-up-address"
          label="Address"
          name="patientAddress"
          autoComplete="address"
          type="address"
          value={address || ""}
          onChange={(e) => setAddress(e.target.value)}
        />
        &nbsp;
        <Grid item xs={12}>
          <RadioGroup aria-label="preferred contact" name="preferredmethod" value={preferredMethod || "email"} onChange={(e) => setPreferredMethod(e.target.value)}>
            <FormLabel textAlign='left'>Preferred Method of Contact</FormLabel>
            <FormControlLabel value="email" control={<Radio />} label="Email" />
            <FormControlLabel value="phone" control={<Radio />} label="Phone" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign='center'>
            <Button 
              id="save"
              name="save"
              variant ="contained"
              onClick={() => saveChanges()}
            >
              Save Patient Profile
            </Button>
          </Box>
        </Grid>
      </Box>
      &nbsp;
      <hr />
      &nbsp;
      <Box sx={{ display: 'flex', flexWrap: 'wrap'}}>
        <Grid item xs={12}>
          <Typography variant='h5' align='center'>Assigned Doctor</Typography>
        </Grid>
        &nbsp;
        {!loading ?
          <>
            {hasDoctor ?
              <>
                <TextField
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  id="doctor-name"
                  label="Doctor Name"
                  value={doctorName}
                  inputProps={
                    { readOnly: true, }
                  }
                />
                <TextField
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  id="doctor-npi"
                  label="Doctor NPI"
                  name="patientAddress"
                  value={doctorNPI}
                  inputProps={
                    { readOnly: true, }
                  }
                />
              </>
              :
              <>
                <Grid item xs={12}>
                  <Typography align='center'>Patient does not currently have an assigned doctor. To select a doctor for this patient, click the button below.</Typography>
                </Grid>
              </>
            }
          </>
          :
          <Grid item xs={12}>
            <Box textAlign='center'>
              <CircularProgress/>
            </Box>
          </Grid>
        }
        &nbsp;
        <Grid item xs={12}>
          <Box textAlign='center'>
            <Button 
              id="save"
              name="save"
              variant ="contained"
              onClick={() => handleOpen()}
            >
              <><SearchIcon/></> Search for New Doctor
            </Button>
          </Box>
        </Grid>
        &nbsp;
        <Dialog open={open} onClose={handleClose}>
            <DoctorSearch userId={userId} setAssignDoctorSuccess={setAssignDoctorSuccess}/>
        </Dialog>
        {saved && <Alert children={"Profile Saved Successfully"}/>}
        {assignDoctorSuccess && <Alert children={"Doctor Assigned Successfully"}/>}
      </Box>        
    </>
  );
});

export default Profile;
