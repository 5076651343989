import React, {Component} from 'react'
import AppWrapper from './AppWrapper'

class AuthWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
    }
    this.updateUsername = this.updateUsername.bind(this)
  }

  updateUsername(newUsername) {
    this.setState({username: newUsername})
  }

  render() {
    return (
      <div className="flex-1">
        <AppWrapper authState={this.props.authState} onStateChange={this.props.onStateChange} />
      </div>
    )
  }
}

export default AuthWrapper