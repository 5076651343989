import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useEffect, useState } from "react";
import { Loading } from ".";
import getToken from "../utils/get-token";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

const UnconfirmedPatients = withAuth0(() => {
  const classes = useStyles(); 
  const [data, setData] = useState("");
  const [gotData, setGotData] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();

  useEffect(() => {
    if(!setData){
      return;
    }
    
    fetchUnconfirmedPatients()
      .then((result)=> { 
        console.log(result)
        setData(result);
        setGotData(true); 
      });
           
  }, [setData]);

  async function deleteAccount(username) {
    const fetchData = async () => {
      const npiResponse = await fetch(`${apiUrl}/users/${username}`, {
        method: "DELETE",
        headers: {
          "Authorization": await getToken(),
        },
      })
      console.log(npiResponse)
      return npiResponse.json();
    }

    fetchData().then(()=> {
      fetchUnconfirmedPatients().then((result)=> {
        console.log(result)
        setData(result);
        setGotData(true); 
      });
    });
  };
  
  const fetchUnconfirmedPatients = async () => {
    const npiResponse = await fetch(`${apiUrl}/users/unconfirmed`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": await getToken(),
      },
    })
    console.log(npiResponse)
    return npiResponse.json();
  }

  if (!gotData) {
    return <Loading />;
  }

  return (
    <>
      <Container className={classes.container}>
          &nbsp;
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">Unconfirmed Patients</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">
                Patient E-mail
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h5">
                Account Created
              </Typography>
            </Grid>  
            <Grid item xs={3}>
              <Typography variant="h5">
                Account Status
              </Typography>
            </Grid>  
            {data && data.sort((a, b) => a.email - b.email).map(patient => {
              return <React.Fragment key={patient.email}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    {patient.email}
                  </Typography>
                </Grid>
                <Grid item xs={3}>{patient.created}</Grid>
                <Grid item xs={3}>{patient.status}</Grid>
                <Grid item xs={1} style={{textAlign: 'right'}}>
                <Button
                  variant ="contained"
                  aria-label="outlined primary button group"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => {            
                    deleteAccount(patient.email)
                  }}
                  >
                    Delete
                </Button>
                </Grid>              
              </React.Fragment>;
            })}
          </Grid>
        </Container>
      </>
    );
});

export default UnconfirmedPatients;
