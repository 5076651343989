import React, { useEffect, useState } from "react";
import { Loading } from ".";
import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import getToken from "../utils/get-token";
import { LoadingEmbed } from "./loading";

const DISPLAY_PAGE = 10;

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

const Admins = withAuth0(() => {
  const classes = useStyles();
  const [ requestNumber, setRequestNumber ] = useState(0);
  const [userData, setUserData] = useState([]);
  const [nextToken, setNextToken] = useState('');
  const [ makeRoleRequest, setRoleRequest ] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();

  const fetchAdminData = async () => {
    console.log(`${apiUrl}/admins/${nextToken}`);
    let urlBuilder = new URL(`${apiUrl}/admins`);
    urlBuilder.search = new URLSearchParams({
      nextToken: nextToken ? nextToken : '',
    });
    let url = urlBuilder.toString();
    const npiResponse = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": await getToken(),
      },
    })
    console.log(npiResponse);
    return npiResponse.json();
  }

  useEffect(() => {
    if ((pageIndex + 1) >= userData.length / DISPLAY_PAGE && (nextToken !== '' || pageIndex == 0)){
      fetchAdminData().then((result)=> { 
        const {userGroup, token} = result;
        console.log(result)

        setNextToken(token ? token : '');
        setUserData(userData.concat(userGroup));
        if(userGroup.length == 0 && pageIndex == 0){
          setNoResults(true);
        }
      });
    }
  }, [pageIndex]);

  const modifyPageIndex = (isIncrement) => {
    console.log(nextToken);
    if (!isIncrement){
      setPageIndex(pageIndex - 1);
      return;
    } else {
      setPageIndex(pageIndex + 1);
    } 
  }

  useEffect(() => {
    if(!makeRoleRequest){
      return;
    }

    const fetchData = async () => {
      let newRole;

      if (makeRoleRequest.role === "Doctor"){
        console.log("set role request: " + makeRoleRequest.role)
        newRole = "Doctor";
      }
      else if (makeRoleRequest.role === "Patient"){
        console.log("set role request: " + makeRoleRequest.role)
        newRole = "Patient";
      }
      else{
        console.log("set role request: " + makeRoleRequest.role)
        newRole = "Manufacturer";
      }
  
      const response = await fetch(`${apiUrl}/users/${makeRoleRequest.username}/updaterole`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": await getToken(),
        },
        body: JSON.stringify({userGroup: newRole}),
      });
        
      console.log(response);

      setRoleRequest(null);
      setRequestNumber(r => r + 1);
    }

    fetchData();
  }, [makeRoleRequest]);

  async function deleteAccount(username) {
    const fetchData = async () => {
      const npiResponse = await fetch(`${apiUrl}/users/${username}`, {
        method: "DELETE",
        headers: {
          "Authorization": await getToken(),
        },
      })
      console.log(npiResponse)
      return npiResponse.json();
    }

    fetchData()
      .then(()=> {
        setRequestNumber(r => r + 1);
      });
  };

  const IsolatedMenu = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
   
    return(
    <React.Fragment>
      <Button
      variant ="contained"
      aria-label="outlined primary button group"
      aria-controls="simple-menu"
      aria-haspopup="true"
      onClick={event => setAnchorEl(event.currentTarget)}
      >
        <ArrowDropDownIcon />
      </Button>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.patient,
              role: "Doctor"
            })
        }}>Make Doctor</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.patient,
              role: "Patient"
            })
        }}>Make Patient</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.patient,
              role: "Manufacturer"
            })
        }}>Make Manufacturer</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            deleteAccount(props.patient);
        }}>Delete Account</MenuItem>
        <MenuItem onClick={() => {
            window.location.href = `/transactions/${props.doctor.username}`;
        }}>View Account Transactions</MenuItem>
      </Menu>
    </React.Fragment>
    )
  }

  if ((userData.length == 0) || makeRoleRequest) {
    return <Loading />;
  }

  let dataSubset = [];
  if (userData){
    console.log(userData);
    dataSubset = userData.slice(pageIndex* DISPLAY_PAGE, (pageIndex*DISPLAY_PAGE) + DISPLAY_PAGE)
  }

  return (
    <>
      <Container className={classes.container}>
        <Typography variant="h4">Admins</Typography>
        &nbsp;
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography variant="h5">
              Admin E-mail
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h5">
              Account Created
            </Typography>
          </Grid>
          {noResults && 
              <Grid item xs={12} style={{textAlign: 'center'}}>
                <Typography variant="h5">
                  No results
                </Typography>
              </Grid>
            }
          {!noResults && dataSubset.length == 0 && <Grid item xs={12}><LoadingEmbed/></Grid>}
          {(userData) && dataSubset.sort((a, b) => a.email - b.email).map(admin => {
            return <React.Fragment key={admin.email}>
              <Grid item xs={4}>
                <Typography variant="body1">
                  {admin.email}
                </Typography>
              </Grid>
              <Grid item xs={4}>{admin.created}</Grid>
              <Grid item xs={4} style={{textAlign: 'left'}}>
                  <IsolatedMenu patient={admin.email}/>
              </Grid>
            </React.Fragment>;
          })}
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12}>
            <Button onClick={() => modifyPageIndex(false)} disabled={pageIndex === 0}><ArrowBackIosIcon/></Button>
              <Button onClick={() => modifyPageIndex(true)} disabled={nextToken == '' && ((pageIndex+1)*DISPLAY_PAGE >= userData.length)}><ArrowForwardIosIcon/></Button>
              {/* <Pagination hideNextButton={true} hidePrevButton={true} count={dataCount} onChange={event => modifyPageIndex(event)}/> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
    );
  
});

export default Admins;