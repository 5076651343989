import React from 'react';
import { Bar } from 'react-chartjs-2';

function SessionsGraph({ sessions, duration, showEmptyDays }) {
  
    function addAllDaysForTwoYears(data) {
        // Create a dictionary to count sessions by date
        const sessionCount = {};
        data.forEach(session => {
            if (session.date in sessionCount) {
                sessionCount[session.date] += 1;
            } else {
                sessionCount[session.date] = 1;
            }
        });
    
        // Create a date list for the past 2 years
        const endDate = new Date();
        const startDate = new Date(endDate.getFullYear() - 2, endDate.getMonth(), endDate.getDate());
        const dateList = [];
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
            dateList.push(new Date(d));
        }
    
        // Create the result list with date and session count
        const result = dateList.map(date => {
            const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            return {
                date: formattedDate,
                sessionCount: sessionCount[formattedDate] || 0
            };
        });
    
        return result;
    }
    let XSessions = addAllDaysForTwoYears(sessions);

    // Aggregate the sessions by date
  const aggregatedData = XSessions.reduce((acc, XSessions) => {
    acc[XSessions.date] = (acc[XSessions.date] || 0) + XSessions.sessionCount;
    return acc;
  }, {});
  

    // If no sessions, display an icon and a message
  if (Object.keys(aggregatedData).length === 0) {
    return (
      <div style={{ textAlign: 'center' }}>
        <span role="img" aria-label="No data">⚠️</span>
        <p>No session data available to display.</p>
      </div>
    );
  }

  // Filter the data based on duration input
  const endDate = new Date();
  let startDate;

  switch (duration) {
    case 30:
      startDate = new Date(endDate - 30 * 24 * 60 * 60 * 1000);
      break;
    case 60:
      startDate = new Date(endDate - 60 * 24 * 60 * 60 * 1000);
      break;
    case 90:
      startDate = new Date(endDate - 90 * 24 * 60 * 60 * 1000);
      break;
    default:
      startDate = new Date(Math.min(...XSessions.map(session => new Date(session.date))));
  }

  const filteredData = Object.keys(aggregatedData)
    .filter(date => new Date(date) >= startDate && new Date(date) <= endDate)
    .reduce((acc, date) => {
      acc[date] = aggregatedData[date];
      return acc;
    }, {});

  // Remove days without session data if showEmptyDays is false
  if (!showEmptyDays) {
    Object.keys(filteredData).forEach(date => {
      if (!filteredData[date]) delete filteredData[date];
    });
  }

// If no sessions were logged during the specified term
if (Object.keys(filteredData).every(date => filteredData[date] === 0)) {
    return (
      <div style={{ textAlign: 'center' }}>
        <span role="img" aria-label="No sessions">📅</span>
        <p>No sessions logged during this term.</p>
      </div>
    );
  }
  
  // Prepare data for the chart
  const chartData = {
    labels: Object.keys(filteredData),
    datasets: [{
      label: 'Number of Sessions',
      data: Object.values(filteredData),
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
    }]
  };

  return <Bar data={chartData} />;
}

export default SessionsGraph;