import React from 'react'
import {Authenticator} from 'aws-amplify-react'
//import './App.css'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import AuthWrapper from './AuthWrapper'
import './app.css'
import { useSelector } from 'react-redux';
import { getRequireMFA } from './MFA-setup-slice'
Amplify.configure(awsconfig)


function App() {
  const mfa = useSelector(getRequireMFA);
  return (
    <div className="App">
      <header className="App-header">
        <Authenticator hideDefault={true} amplifyConfig={awsconfig}>
          <AuthWrapper authState = {mfa}/>
        </Authenticator>
      </header>
    </div>
  )
}

export default App