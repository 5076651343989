import React from 'react'
import App from './InternalApp'
import SignIn from './SignIn'
import { useSelector } from 'react-redux';
import { getSetupTOTP } from './MFA-setup-slice'
import SetupTOTP from './SetupTOTP';
const AppWrapper = ({authState}) => {
  let mfaStatus = authState;

  let setupTOTP = useSelector(getSetupTOTP);
  let code = useSelector(state => state.MFA.code);
  let username = useSelector(state => state.MFA.username);
  let user = useSelector(state => state.MFA.user);

  let mfaResult = mfaStatus ? mfaStatus.toString() : undefined;
  let mfaRequired = true;

  if(mfaResult !== undefined) {
    mfaRequired = mfaResult.requireMFA;
  }

  if(authState === 'signedIn' && !mfaRequired && !setupTOTP) {
    return (
      <>
        <App/>
      </>
    )
  }
  if(authState === 'signedIn' && setupTOTP) {
    return (
      <>
    <SetupTOTP code={code} username={username} user={user}/>
    </>)
  }
  else {
    return (
      <>
        <SignIn/>
      </>
    )
  }
}

export default AppWrapper;