import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useEffect, useState } from "react";
import { Loading } from ".";
import GetPrescriptions from "./get-prescriptions";
import Client from 'shopify-buy';

const Storefront_Key = process.env.STOREFRONT_ACCESS_TOKEN;
const Storefront_URL = process.env.STOREFRONT_URL;

const client = Client.buildClient({
  storefrontAccessToken: Storefront_Key,
  domain: Storefront_URL
});
const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

const GetPrescriptionWrapper = withAuth0(() => {
  console.log(Storefront_Key);
  console.log(Storefront_URL);

  const classes = useStyles(); 

  return (
    <>
      <Container className={classes.container}>
          &nbsp;
          <GetPrescriptions client={client}/>
      </Container>
      </>
    );
});

export default GetPrescriptionWrapper;
