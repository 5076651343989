import React from "react";

import { DoctorOptions } from "../components";

const Home = () => (
  <>
    <DoctorOptions />
  </>
);

export default Home;
